import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiService } from "../../Components/Services/apiServices";
import { TailSpin } from "react-loader-spinner";
import moment from "moment";
let apiServices = new ApiService();

function TransactionStatus(){
    const [orderDetail, setOrderdetail] = useState("")
    const [spinnerLoading, setSpinnerLoading] = useState(false)
    const didMountRef = useRef(true)
    const navigate = useNavigate()
    const {id} = useParams()
    useEffect(() => {
        if (didMountRef.current) {
   
            let dataString = {
                txn_transaction_id: id
            }
            setSpinnerLoading(true)
            apiServices.getuserOrderdetailgetrequest(dataString).then((response) => {
                if (response?.data?.status == "success") {
                    setOrderdetail(response?.data?.data) 
                    setTimeout(() => {
                      
                      checkupistatus("direct" ,response.data.data.trans_type)
                    }, 2000);
   
                }
                else if (response.data.status === "error" && response.data.message === "Session expired") {
                    navigate("/");
                    localStorage.removeItem('AUTH_TOKEN');
                    setSpinnerLoading(false)
                }
            })
                .catch((error) => {
                    setSpinnerLoading(false)
            })
           

      
               
            }
                    didMountRef.current = false;

    }, []);

    const checkupistatus =(type,transtype)=>{
      console.log(transtype , "orderDetail.trans_type")
      var childorder = ""
      if(transtype == 'SIP'){
         childorder = 1
      }
      const dataString={
          transactionid : id,
          type:'TRANSACTIONSTATUS',
        childorder:childorder
           
      }
      apiServices.paymentcallbackurlpostrequest(dataString).then(res => {
          if (res.data.status == "success") {
            setSpinnerLoading(false)
            if(type == "refresh"){
              window.location.reload();
            }
              // navigate("/transaction-status/"+res.data.trans_id)
          }else{
            setSpinnerLoading(false)
              alert(res.data.message);
              // navigate("/transaction-status/"+res.data.trans_id)
              return false;
          }

      })
   }

    return(
<>
<div className={spinnerLoading == true ? 'parentDisable' : ''} width="100%">
          <div className='overlay-box'>
            <TailSpin
              color="#ffffff"
              visible={spinnerLoading}
            />
          </div>
          </div>
<div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-8 col-lg-6">
          <div className="card shadow-lg">
            <div className="card-body text-center">
              {/* Add the logo here */}
              <img
                src='/img/logo.png'
                alt="Company Logo"
                className="mb-2"
                style={{ maxWidth: '150px' }}
              />
                <p className="lead">{orderDetail.trans_mf_name}</p>
            {orderDetail && orderDetail.trans_payment_status == 1 ?
            <>
               <img src="/img/verifypay.png" className="wd-60"></img>
              <h2 className="mt-4" style={{color:"#304aa6"}}>PAYMENT SUCCESSFUL</h2></>
               :
               orderDetail ?
               <>
               <img src="/img/pendingpay.png" className="wd-60"></img>
               <h2 className="mt-4" style={{color:"#304aa6"}}>PAYMENT PENDING</h2></>
            :''
            }
            
              <hr />
              <div className="mt-4">
                <h5>Transaction Details</h5>
                <table className="table table-bordered mt-3">
                  <tbody>
                    <tr>
                      <th>Transaction ID</th>
                      <td>{orderDetail.trans_regid}</td>
                    </tr>
                    <tr>
                      <th>Transaction Type</th>
                      <td>{orderDetail.trans_type}</td>
                    </tr>
                    <tr>
                      <th>Amount Paid</th>
                      <td>₹{orderDetail.trans_amount}</td>
                    </tr>
                    <tr>
                      <th>Date</th>
                      <td>{moment(orderDetail.created_at).format('DD-MMM-YYYY')}</td>
                    </tr>
                    <tr>
                      <th>Payment Method</th>
                      <td>{orderDetail.trans_payment_mode}</td>
                    </tr>
                  </tbody>
                </table>
                <div className="d-flex justify-content-center mt-3">
                  {orderDetail.trans_payment_status != 1 ? <a onClick={()=>checkupistatus("refresh",orderDetail.trans_type)} className="btn btn-primary me-2">Refresh Status</a> : 
                  <a href="/dashboard" className="btn btn-primary me-2">Go To Dashboard</a>}
                  
                  <a href="/order" className="btn btn-outline-secondary ml-2">View Transactions</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</>
    )
}
export default TransactionStatus