import React, { useEffect, useRef, useState } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useNavigate, useParams } from "react-router-dom";
import CurrencyInput from 'react-currency-input-field';
import { ToastContainer, toast } from 'react-toastify';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { BrowserView, MobileView } from "react-device-detect";
import SweetAlert from 'react-bootstrap-sweetalert';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { createPortal } from "react-dom";
import HtmlResponseRenderer from "../HtmlResponseRenderer";
import { ApiService } from "../../Components/Services/apiServices";

let apiServices = new ApiService();


function MutualFundsdetail() {

    const { id } = useParams()
    const { goalID } = useParams()
    const navigate = useNavigate()
    const [mutualFundDetail, setMutualFunfDetail] = useState({})
    const [sipDetail, setSipDetail] = useState([])
    const [baseUrl, setBaseUrl] = useState("")
    const [step, setStep] = useState(1)
    const [mfSipDates, setMfSipDates] = useState([])
    const [handleMfSipDates, setHandleMfSipDates] = useState("")
    const [inputValue, setInputValue] = useState('');
    const [frequencyData, setFrequencyData] = useState("")
    const [changeSIPClassName, setChangeSIPClassName] = useState()
    const [changeOneTimeClassName, setChangeOneTImeClassName] = useState()
    const [oneTimeAmount, setOneTimeAmount] = useState('')
    const [showOTPScreen, setShowOTPScreen] = useState(false)
    const [trancOTP, setTrancOTP] = useState("")
    const [loaderName, setLoaderName] = useState("")
    const [HandleMfSipDuration, setHandleMfSipDuration] = useState("25")
    const [showOneTimeOTPScreen, setShowOneTimeOTPScreen] = useState(false)
    const [show, setShow] = useState(false)
    const [matchedOTPMessage, setMatchedOTPMessage] = useState("")
    const [warningShow, setWarningShow] = useState(false)
    const [counter, setCounter] = React.useState(0);
    const [countermob, setCountermob] = React.useState(0);
    const [seconds, setseconds] = useState(0);
    const [minutes, setminutes] = useState(10);
    const [isLoading, setIsLoading] = useState(false)
    const [showSkeleton, setShowSkeleton] = useState(false)
    const [autoPayMessage, setAutoPayMessage] = useState("Please Set Autopay/Mandate For Your Primary Account")
    const [minimumMonth, setMinimumMonth] = useState("")
    const [minAmount, setMinAmount] = useState("")
    const [maxAmount, setMaxAmount] = useState("")
    const [calenderDates, setCalenderDates] = useState([])
    const [bankData, setbankData] = useState([])
    const [selectBank ,setSelectBank] = useState("")
    const [selectPaymentMode ,setSelectPaymentMode] = useState("")
    const [bankype , setBankType] = useState("")
    const [transId , setTransId] = useState("")
    const [open, setOpen] = useState(false);
    const [FirstOrderShow, setFirstOrderShow] = useState(false);
    const [htmlData , sethtmlData] = useState("")
    const [neftReference , setNeftReferene] = useState("")
    const [upiId , setUpiId] = useState("")
    const [bankName , setBankName] = useState("")
    const [accountNumber , setAccountNumber] = useState("")
    const [mandateDates ,setmandateDates] = useState("")
    const [defaultMandate , setDefaultmandate] = useState("")
    const [enableDates , setEnableDates] = useState("")
    const [FolioNumberSelect, setFolioNumberSelect] = useState("");
    const [folioNumber, setFolioNumber] = useState([])
    const [folioMobileNumber , setFolioMobileNumber] = useState("")
    const [mandateStatus , setMandateStatus] = useState("")
    const [tempOtpId , setTempOtpId] = useState("")
    const [upiStatus , setUpiStatus] = useState(false)
    const [ubdtStatusText , setUbdtStatusText] = useState("")
    const [ubdtStatus , setUbdtStatus] = useState("")
    const [ubdtStatusClass , setUbdtStatusClass] = useState("")
    const [kycStatus , setKycStatus] = useState("")
    const [FOFFlag , setFOFFlag] = useState("N")
    const [checkUserKyc , setCheckUserKyc] = useState(0)
    const [showmandatestatus3 , setShowMandateStatus3] = useState(false)
    const [transSipId , setTransSipId] = useState("")
    const [upiSipStatus , setUpiSipStatus] = useState(false)
   
    const didMountRef = useRef(true)
    
    useEffect(() => {
        if (didMountRef.current) {

       
            getbankdata()
            setChangeSIPClassName(true)
            const dataString = {
                mf_id: id
            }
            setShowSkeleton(true)
            apiServices.getmutualfundsdetailpostrequest(dataString).then((result) => {
                if (result.data.status === 'success') {
                    setMutualFunfDetail(result.data.data)
                    setBaseUrl(result.data.imgeURL)
                    setSipDetail(result.data.data.sip_detail)
                    setFolioNumber(result.data.folioData)
                    setShowSkeleton(false)
                    setCheckUserKyc(result.data.checkuserkyc)

                    setUbdtStatus(result.data.userdata.gs_ubdt_status)
                    setKycStatus(result.data.userdata.gs_kyc_status)
                    if(result.data.userdata.gs_kyc_status == 0 || result.data.userdata.gs_kyc_status == 4){
                        setUbdtStatusText("Mutual fund KYC is required to be done")
                        setUbdtStatusClass("alert alert-warning")
                     }
                    //  else if(result.data.data.gs_kyc_status == 2){
                    //     setUbdtStatusText("We are unable to process your request")
                    //     setUbdtStatusClass("alert alert-warning")
                    //  }
                    else if(result.data.userdata.gs_ubdt_status == '01'){
                        setUbdtStatusText("Mutual Fund KYC under process")
                        setUbdtStatusClass("alert alert-warning")
                    }
                   
                    else if(result.data.userdata.gs_ubdt_status == '02'){
                        setUbdtStatusText("Mutual fund KYC Update needed")
                        setUbdtStatusClass("alert alert-danger")
                    }
                    else if(result.data.userdata.gs_ubdt_status == '03'){
                        setUbdtStatusText("Mutual Fund KYC is on Hold")
                        setUbdtStatusClass("alert alert-danger")
                    }
                    else if(result.data.userdata.gs_ubdt_status == '04'){
                        setUbdtStatusText("Mutual Fund KYC is rejected Status")
                        setUbdtStatusClass("alert alert-danger")
                    }
                    else if(result.data.userdata.gs_ubdt_status == '05' || result.data.userdata.gs_ubdt_status == '06'){
                        setUbdtStatusText("KYC is required to be done")
                        setUbdtStatusClass("alert alert-warning")
                    }

                } else if (result.data.status === "error" && result.data.message === "Session expired") {
                    navigate("/");
                    localStorage.removeItem('AUTH_TOKEN');
                }
            })
                .catch((error) => {
                });
                const disabledDate = new Date(moment(mandateDates).format("YYYY ,M , DD"));       
                for (let i = 0; i < 5; i++) {
                    const disabledDay = new Date(disabledDate);
                    disabledDay.setDate(disabledDay.getDate() + i);
                    disabledDates.push(disabledDay);
                
                }
                const disabledDay = new Date(disabledDate);
                disabledDay.setDate(disabledDay.getDate() + 5); // Adding 5 days
                
                const disabledDatesrr = [disabledDay];
                // setEnableDates(disabledDatesrr[0])
            }
                    didMountRef.current = false;

    }, []);


    const frequencyHandleChange = (id, date, minamount, maxamount, mfsipdates) => {
     if(mfsipdates){
        setCalenderDates(mfsipdates.split(','))
     }
     else{
     }
        setFrequencyData(id)
        setMfSipDates(date.split(','))
        setMinimumMonth(date)
        setMaxAmount(minamount)
        setMinAmount(maxamount)
    }

    const gotoStep2 = () => {
     
        console.log(upiId,"mandateStatus")
        if (frequencyData == "" && step == 1) {
            toast.error("Please select the frequency of SIP");
            return;
        }
        if (startDate == "" && step == 2) {
            toast.error("Please select date to deduct SIP amount");
            return;
        }
        if (minimumMonth > HandleMfSipDuration * 12 && step == 2) {
            toast.error("Please select minimum duration");
            return;
        }
        if (inputValue == "" && step == 3) {
            toast.error("Please enter amount");
            return;
        }
        if (eval(minAmount) > eval(inputValue) && step == 3) {
            toast.error("Please select minimum Amount");
            return;
        }
        if (eval(maxAmount) <= eval(inputValue) && step == 3) {
            toast.error("Please select maximum Amount");
            return;
        }
        if (oneTimeAmount == "" && step == 5) {
            toast.error("Please enter amount ");
            return;
        }
        if(mandateStatus == 0){
            toast.error("Bank Autopay Status Is Not Approved, Please Approve It From Bank & Autopay Section .")
            return;
        }
        
        

        setStep(step + 1)
    }


    React.useEffect(() => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    }, [counter]);

    React.useEffect(() => {
        countermob > 0 && setTimeout(() => setCountermob(countermob - 1), 1000);
    }, [countermob]);


    React.useEffect(() => {
        setTimeout(() => {
            if (seconds > 0) {
                setseconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                } else {
                    setseconds(59);
                    setminutes(minutes - 1);
                }
            }
        }, 1000);
    });


    function addNumberSuffix(number) {
        const lastDigit = number % 10;
        const lastTwoDigits = number % 100;

        // Special case for numbers ending in 11, 12, and 13
        if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
            return number + 'th';
        }

        switch (lastDigit) {
            case 1:
                return number + 'st';
            case 2:
                return number + 'nd';
            case 3:
                return number + 'rd';
            default:
                return number + 'th';
        }
    }

    const handleValueClick = (amount) => {
        const newValue = parseFloat(inputValue || 0) + amount;
        setInputValue(newValue);
    };
    const handleValueClickonetime = (amount) => {
        const newValue = parseFloat(oneTimeAmount || 0) + amount;
        setOneTimeAmount(newValue);
    };

    const clickonSIP = () => {
        setChangeSIPClassName(true)
        setChangeOneTImeClassName(false)
        setFrequencyData("")
        setHandleMfSipDates("")
        setHandleMfSipDuration("")
        setInputValue("")
        setStep(1)
    }
    const clickonOneTime = () => {
        setChangeSIPClassName(false)
        setChangeOneTImeClassName(true)
        setStep(5)
    }
    const sendOTP = () => {


        if (oneTimeAmount == "" && step == 5) {
            toast.error("please Enter Amount");
            return;
        }

        setIsLoading(true)
        const dataString={
            type :"SIP",
            ubd_id:selectBank
        }
        apiServices.sendotptransactionPostRequest(dataString).then((result) => {
            if (result.data.status == "success") {
                setTempOtpId(result.data.temp_id)
                setIsLoading(false)
                setLoaderName("Send OTP")
                setCounter(30);
                setCountermob(30)
                setShowOTPScreen(true)
            }
            else {
                setIsLoading(false)
                toast.error(result.data.message)
            }
        })
    }


    const resendOtpbr = () => {
        setIsLoading(true)
        const dataString={
            type :"SIP"
        }
        apiServices.sendotptransactionPostRequest(dataString).then(res => {
            if (res.data.status == 'success') {
                setTempOtpId(res.data.temp_id)
                setCounter(30);
                setCountermob(30)
                setminutes(10);
                setseconds(0);
                setIsLoading(false)
                return false;
            }
            else {
                toast(res.data.message);
                return false;
            }
        })
    }
    const verifyOTP = () => {
         setIsLoading(true)
        const dataString = {
            otp: trancOTP,
            temp_otp_id : tempOtpId
        }
        apiServices.verifyotptransactionPostRequest(dataString).then((result) => {
            if (result.data.status == "success") {
                // setLoaderName("Verify OTP")
                const dataString1 = {
                    frequencyType: frequencyData,
                    StartDate: moment(startDate).format('DD/MM/YYYY'),
                    InstallmentAmount: inputValue,
                    mf_id: id,
                    SipYear: HandleMfSipDuration,
                    bank_id:selectBank,
                    goal_id:goalID,
                    temp_otp_id : tempOtpId,
                    FOF:FOFFlag,
                    payment_mode:selectPaymentMode,
                    upi:upiId,
                   
                }
             
                apiServices.createbseXSIPorderPostRequest(dataString1).then((result) => {
                    if (result.data.status == "success") {
                        setTransSipId(result.data.trans_id)
                        if(FOFFlag == 'Y'){
                        setFirstOrderShow(true)
                        }else{
                        setShow(true)
                        }
                        setMatchedOTPMessage(result.data.message)
                        setIsLoading(false)
                    }
                    else if (result.data.status == "error") {
                        setWarningShow(true)
                        setMatchedOTPMessage(result.data.message)
                        setIsLoading(false)
                    }

                })

            }

            else if (result.data.status == "error") {
                toast.error(result.data.message)
                setIsLoading(false)
            }

        })
    }
    const checkbankdirect=( event)=>{
        const selectedValue = event
          const [bseBId, bseBankNodal,bankName,accountNumber , mandateDate , madateStatus] = selectedValue.split('_');
          console.log(bseBankNodal)
          
           if(madateStatus == 3){
            setShowMandateStatus3(true)
            setFOFFlag('Y');
            }else{
            setShowMandateStatus3(false)
            setFOFFlag('N');
            }
          setBankName(bankName)
          setSelectBank(bseBId)
          setBankType(bseBankNodal)
          setAccountNumber(accountNumber)
          setmandateDates(mandateDate)
          setMandateStatus(madateStatus)
          const today = new Date();
          const tomorrow = new Date(today);
          tomorrow.setDate(today.getDate() + 3);
          
          // Format the date as "YYYY, M, DD"
          const year = tomorrow.getFullYear();
          const month = tomorrow.getMonth() + 1; // getMonth() returns 0-based month
          const day = tomorrow.getDate();
  

          const disabledDate = new Date(moment(mandateDate).format("YYYY ,M , DD"));
          const disabledDates = [];
          for (let i = 0; i < 5; i++) {
              const disabledDay = new Date(disabledDate);
              disabledDay.setDate(disabledDay.getDate() + i);
              disabledDates.push(disabledDay);
          }

          const disabledDay = new Date(disabledDate);
          disabledDay.setDate(disabledDay.getDate() + 5); // Adding 5 days
          
          const disabledDatesrr = [disabledDay];
          if(moment(mandateDate).format("YYYY ,M , DD") === moment(new Date()).format("YYYY ,M , DD")){
          setEnableDates(disabledDatesrr[0])
          setStartDate(disabledDatesrr[0])
          }
          else{
            setEnableDates(new Date(`${year}, ${month}, ${day}`))
            setStartDate(new Date(`${year}, ${month}, ${day}`))
          }
          const enabledDates = generateEnabledDates();
          const defaultMandateDates = enabledDates.filter(date => !disabledDates.some(disabled => disabled.getTime() === date.getTime()));
          setDefaultmandate(defaultMandateDates);
            }
            
    const sendOneTimeOTP = () => {
  
       if(upiId == "" && selectPaymentMode == "UPI"){
        toast.error("UPI Id is Required")
        return
       }
       if(neftReference == "" && selectPaymentMode == "NEFT"){
           toast.error("NEFT Reference is Required")
           return 
       }
        if (selectBank == "" && step == 5) {
            toast.error("Please select bank");
            return;
        }
        if (oneTimeAmount == "" && step == 5) {
            toast.error("Please Enter Amount");
            return;
        }
        // if (oneTimeAmount <= mutualFundDetail.mf_min_pur_amt && step == 5) {
        //     toast.error(`Please enter minimum amount`);
        //     return;
        // }
        setIsLoading(true)
        if(FolioNumberSelect == ""){
            const dataString={
                type : 'ONE TIME'
            }
        apiServices.sendotptransactionPostRequest(dataString).then((result) => {
            if (result.data.status == "success") {
                setTempOtpId(result.data.temp_id)
                setIsLoading(false)
                setLoaderName("Send OTP")
                setCounter(30);
                setCountermob(30)
                setShowOneTimeOTPScreen(true)
            }
            else {
                setIsLoading(false)
                toast.error(result.data.message)
            }
        })
    }
    else{
        const dataString={
            folionumber : FolioNumberSelect
        }
        apiServices.sendotpredeemtransactionpostrequest(dataString).then(res=>{
            if(res.data.status == "success"){
                setShowOneTimeOTPScreen(true)
                const maskedNumber = "X".repeat(res.data.mobilenumber.length - 4) + res.data.mobilenumber.slice(-4);
                setFolioMobileNumber(maskedNumber)
                setCounter(30);
                setIsLoading(false)
                setTempOtpId(res.data.temp_id)
            }
            else{
                toast.error(res.data.message)
                setIsLoading(false)
            }
    }
        )}
    }

    const KYCprocess = (e) => {
        let applicationtype = ""
         if(ubdtStatus == '02' || ubdtStatus == '03' || ubdtStatus == '05'|| ubdtStatus == '06'){
            applicationtype = "modify"
         }
         else if(ubdtStatus == '04'){
            applicationtype = "new"
         }
         const dataString ={
            applicationtype : applicationtype
         }
          apiServices.kycLoginpostrequest(dataString)
            .then(result => {
          
              if (result?.data?.status === "success") {    
                const url = result?.data?.redirectlink;
                window.open(url, '_blank');
              } else {
                toast(result.data.message);
                
              }
            })
            .catch(error => {

            });
        
      };

    const resendOtponetime = () => {
setIsLoading(true)
if(FolioNumberSelect == ""){
    const dataString={
        type : 'ONE TIME'
    }
        apiServices.sendotptransactionPostRequest(dataString).then(res => {

            if (res.data.status == 'success') {
                setTempOtpId(res.data.temp_id)
                setIsLoading(false)
                setCounter(30);
                setCountermob(30)
                setminutes(10);
                setseconds(0);

                // setIsLoading(false)
                // setMobileScreen(false)
                // setverifyotp(true)
                // setuserauthid(res?.data?.auth)
                return false;
            }
            else {
                setIsLoading(false)
                toast(res.data.message);
                return false;
            }
        })
    }
    else{
        const dataString={
            folionumber : FolioNumberSelect
        }
        apiServices.resendredeemotppostrequest(dataString).then(res=>{
            if(res.data.status == "success"){
                setCounter(30);
                setIsLoading(false)
                setTempOtpId(res.data.temp_id)
            }
            else{
                toast.error(res.data.message)
                setIsLoading(false)
            }
    
        })
    }
    }

    const verifyOneTimeOTP = () => {
        setIsLoading(true)
        const dataString = {
            otp: trancOTP,
            temp_otp_id : tempOtpId
        }
        var verifyotp = ""
        if(FolioNumberSelect == ""){
            verifyotp =apiServices.verifyotptransactionPostRequest
        }
        else{
            verifyotp =apiServices.verifyotpredeemtransactionpostrequest
        }
        verifyotp(dataString).then((result) => {
            if (result.data.status == "success") {

                const dataString1 = {
                    InstallmentAmount: oneTimeAmount,
                    mf_id: id,
                    bank_id:selectBank,
                    paymentmode : selectPaymentMode,
                    // InstallmentAmount: oneTimeAmount,
                    // transId : transId,
                    neftReference : neftReference , 
                    upiId : upiId,
                    folionumber : FolioNumberSelect,
                    goal_id:goalID,
                    temp_otp_id : tempOtpId

                }
                
                apiServices.createbsePURCHASEorderpostrequest(dataString1).then((result) => {
                    if (result.data.status == "success") {
                        // setIsLoading(false)
                        // setOpen(true)  
                    if(selectPaymentMode == 'e-mandate'){
                        setShow(true)
                        setMatchedOTPMessage(result.data.message)
                        setIsLoading(false)
                    }else{   
                        setTransId(result.data.transId) 
                        const dataString1 = {
                            InstallmentAmount: oneTimeAmount,
                            mf_id: id,
                            bank_id:selectBank,
                            paymentmode : selectPaymentMode,
                            // InstallmentAmount: oneTimeAmount,
                            // transId : transId,
                            neftReference : neftReference , 
                            upiId : upiId,
                            folionumber : FolioNumberSelect,
                            transId : result.data.transId
                        }
                        apiServices.paymentmethodpostrequest(dataString1).then((result) => {
                if(result.data.status == "success"){
                
                    if(selectPaymentMode == "UPI"){
                        setFirstOrderShow(false)
                        setUpiStatus(true)
                        sethtmlData(result.data.data)
                    }
                    else{
                        setOpen(true) 
                        sethtmlData(result.data.data)
                    }
                    setIsLoading(false)
                    
                }
                else if(result.data.status == "error"){
                    setIsLoading(false)
                    setWarningShow(true)
                    setMatchedOTPMessage(result.data.message)
                }
                        })
                    }
                     

                       
                    }
                    else if(result.data.status == "error" && result.data.message == "An error has occurred."){
                        setIsLoading(false)
                        setWarningShow(true)
                        setMatchedOTPMessage("An error has been occurred while making payment so please complete your payment by link sent to your register email Id")
                    }
                    else if (result.data.status == "error") {
                        setIsLoading(false)
                        setWarningShow(true)
                        setMatchedOTPMessage(result.data.message)
                    }
                  
                })
            }
            else if (result.data.status == "error") {
                setLoaderName("Verify OTP")
                toast.error(result?.data?.message)
                setIsLoading(false)

            }
        })
    }

    const SIPDurationoptions = [];
    for (let i = 2; i <= 29; i++) {
        SIPDurationoptions.push(<option key={i} value={i} >{i} Years</option>);
    }

    const clearLastStep = () => {
        setFrequencyData("")
        setHandleMfSipDates("")
        setHandleMfSipDuration("")
        setInputValue("")
        setStep(1)

    }
    const handleOkClick = () => {
        setShow(false);
        navigate("/order")
    }

    const handlewarningOkClick = () => {
        setWarningShow(false);

        if (matchedOTPMessage == autoPayMessage) {
            // navigate("/bank")

        } else {
            // window.location.reload()
        }
    };
    const currentDate = new Date();
    const futureDate = new Date();
    futureDate.setDate(currentDate.getDate() + 15);
    const filterDates = (date) => {
        return date >= currentDate && date <= futureDate;

    };
    const monthAvailableDates = calenderDates;
    const disabledDates = [];
    const generateEnabledDates = () => {
        const dates = [];

        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth();

        // Dates for the current month
        monthAvailableDates.forEach(day => {
            const date = new Date(currentYear, currentMonth, day);
            dates.push(date);
        });

        // Dates for the next month
        const nextMonth = (currentMonth + 1) % 12; // Using modulo to loop back to January if December
        const nextYear = (currentMonth === 11) ? currentYear + 1 : currentYear; // Increment year if December

        monthAvailableDates.forEach(day => {
            const date = new Date(nextYear, nextMonth, day);
            dates.push(date);
        });
   
        return dates;
    };
    
   
 



    const getbankdata = () => {

        apiServices.userBankDatapostrequest({}).then(res => {
            if (res.data.status == "success") {
                setbankData(res.data.userBankData)
                // if(res.data.userBankData.length > 0){
                // setBankSelect(res.data.userBankData[0].ubd_id)
            }
            // }

        })
    }
  
     const [startDate, setStartDate] = useState(defaultMandate);

   
     const checkupistatus =()=>{
        // setIsLoading(true);
        const dataString={
            transactionid : transId
            
        }
        apiServices.paymentcallbackurlpostrequest(dataString).then(res => {
            if (res.data.status == "success") {
                navigate("/transaction-status/"+res.data.trans_id)
            }else{
                setIsLoading(false);
                alert(res.data.message);
                // navigate("/transaction-status/"+res.data.trans_id)
                return false;
            }
 
        })
     }
     const checksipupistatus =()=>{
        setIsLoading(true);
        const dataString={
            transactionid : transSipId ,
            // transactionid : 458,
            type : "TRANSACTIONSTATUS",
            childorder:1
        }
        apiServices.paymentcallbackurlpostrequest(dataString).then(res => {
            if (res.data.status == "success") {
                navigate("/transaction-status/"+res.data.trans_id)
            }else{
                setIsLoading(false);
                alert(res.data.message);
                // navigate("/transaction-status/"+res.data.trans_id)
                return false;
            }
 
        })
     }

     const getchildorder = () => {
      setIsLoading(true)
           const dataString={
                 trans_id :transSipId,
            // trans_id :461,
                 mf_id: id,
                //  bank_id:62,
                 bank_id:selectBank,
          }
        apiServices.childorderpostrequest(dataString).then(res => {
       
            if(res.data.status == "success"){
                console.log(selectPaymentMode,"selectPaymentMode")
                if(selectPaymentMode == "UPI"){
                setFirstOrderShow(false)
                setUpiSipStatus(true)
                    sethtmlData(res.data.data)
                    setIsLoading(false)
                }
                else{
                    setOpen(true) 
                    sethtmlData(res.data.data)
                    setIsLoading(false)
                }
                setIsLoading(false)
                
            }
            else if(res.data.status == "error"){
                setIsLoading(false)
                // setWarningShow(true)
                toast.error(res.data.message)
            }
        

        })
    }
    return (
        <>
            <ToastContainer position={toast.POSITION.BOTTOM_CENTER} />
            {/* <div className={submitLoader == true ? 'parentDisable' : ''} width="100%">
                <div className='overlay-box'>
                    <Hourglass
                        visible={submitLoader}
                        ariaLabel="Loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        colors={['#304AA7', '#304AA7']}
                    />
                    <p>{loaderName}</p>
                </div>
            </div> */}
            <BrowserView>
                <Header />
                <div className="subheader subheader-small">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/dashboard">Dashboard</Breadcrumb.Item>
                                    <Breadcrumb.Item href="/mutual-funds">Mutual Funds</Breadcrumb.Item>
                                    <Breadcrumb.Item active>{mutualFundDetail.mf_scheme_name ? mutualFundDetail.mf_scheme_name : <Skeleton width="200px" height="10px" />}</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="sec-pad-sm">
                    <div className="container">
                        <div className="row justify-content-center">
                        {checkUserKyc == 0 ?    
                        <div className="col-lg-12 col-12">
                                {ubdtStatusText  ?     
                                <div class={ubdtStatusClass}>
                                    <div style={{fontSize:"13px"}}>
                                    <div style={{marginBottom:"-10px"}}> 
                                    Investment Restricted due to {ubdtStatusText} Please contact us on support@goalstox.com or whatsapp on +919703071249.
                                    </div>
                                    <br></br>
                                    {ubdtStatus == '02' || ubdtStatus == '03' || ubdtStatus == '04' || ubdtStatus == '05' || ubdtStatus == '06' || kycStatus == 1 || kycStatus == 4?
                                    <span  onClick={KYCprocess} style={{cursor:"pointer"}}><strong>Do KYC</strong> <i class="fas fa-arrow-alt-circle-right"></i></span>:""}
                                    </div>

                                    </div>
                                    :""}
                                    </div>
                           : <div className="col-lg-6">
                                <div className="fundbox">
                                    <div className="fundbox-header">
                               
                                            <div className="fundboxdetails">

                                                <div className="fundboxdetails-icon">
                                                    <img src={mutualFundDetail.mf_image != null && mutualFundDetail.mf_image != ""? baseUrl + mutualFundDetail.mf_image : "/img/defaltimage.png"} />
                                                </div>
                                                <div className="fundboxdetails-name">
                                                    <h6>{mutualFundDetail.mf_scheme_name}</h6>
                                                    <div class="mfbox-details-meta"><ul><li>{mutualFundDetail.mf_scheme_type}</li></ul></div>
                                                </div>
                                            </div>

                                        {step !== 1 && step !== 4 && step !== 5 ? <> <div class="fundboxClose"><i class="icon-cross2" onClick={() => {
                                            setStep(1);
                                            setFrequencyData("");
                                            setHandleMfSipDates("");
                                            setHandleMfSipDuration("");
                                            setInputValue("");
                                        }}></i></div></> : ""}
                                        {step == 4 ? <> <div class="fundboxClose"><i class="icon-cross2" onClick={() => { clearLastStep() }}></i></div></> : ""}

                                    </div>
                                    <div className="fundbox-body">
                                        <div className="fundtype mb-5">
                                            <div className="fundtype-inner">
                                                <div className={changeSIPClassName == true ? "fundtypeName active" : "fundtypeName"} onClick={clickonSIP}>SIP</div>
                                                <div className={changeOneTimeClassName == true ? "fundtypeName active" : "fundtypeName"} onClick={clickonOneTime}>One Time</div>
                                            </div>
                                        </div>
                                        {step == 1 ?
                                            mutualFundDetail.mf_sip_flg == 'Y' ?
                                                <div className="funform">
                                                    <div className="funform-inner">
                                                        <div className="funform-group">

                                                            <div className='col-lg-12'>
                                                                <div className="from-group mb-1">
                                                                    <label className="tx-gray">Frequency of SIP</label>
                                                                    <select
                                                                        name="goal_type"
                                                                        className="custom-select"

                                                                        onChange={(e) => {
                                                                            const selectedValue = e.target.value;

                                                                            // Add a check to ensure selectedValue is not undefined or empty
                                                                            if (selectedValue) {
                                                                                const [mfsipId, mfsipFrequency, minAmount, maxAmount, mfsipdates] = selectedValue.split('-');
                                                                                frequencyHandleChange(mfsipId, mfsipFrequency, minAmount, maxAmount, mfsipdates);
                                                                            }
                                                                        }}
                                                                    >
                                                                        <option value={``}>SELECT FREQUENCY</option>
                                                                        {sipDetail.map((value) => (
                                                                            <option value={`${value.mfsip_frequency}-${value.mfsip_min_installment_no}-${value.mfsip_max_installmenyt_amt}-${value.mfsip_min_installmenyt_amt}-${value.mfsip_dates}`} >{value.mfsip_frequency}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-12'>
                                                            <div className="from-group mb-1">
                                                                <label className="tx-gray">Bank</label>
                                                  
                                                        <select name="goal_type" className="custom-select" onChange={(e) => checkbankdirect(e.target.value)}>
                                                            <option value="">Select Bank</option>
                                                            {bankData.map((value) => (
                                                                <option 
                                                                    value={`${value.ubd_id}_${value.bank.bse_bank_nodal}_${value.bank.bse_bank_name}_${value.ubd_acc_no}_${value.created_at}_${value.ubd_mandate_status}`}
                                                                    key={value.ubd_id}
                                                                >
                                                                    {value.bank.bse_bank_name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                            </div>
                                                        </div>
                                                       {showmandatestatus3 ?<>
                                                        <div className='col-lg-12'>
                                                                <div className="from-group mb-1">
                                                                    <label className="tx-gray">Payment Mode</label>
                                                                    <select name="goal_type" className="custom-select" onChange={(e)=>setSelectPaymentMode(e.target.value)}>
                                                                        <option value="">Select payment mode</option>
                                                                   
                                                                        {/* <option value="e-mandate">Auto debit (eNach)</option> */}
                                                                            <option value={bankype}>Bank</option>
                                                                            <option value="UPI">UPI</option>

                                                                            {/* <option value="NEFT">Netbanking</option> */}
                                                              
                                                                    </select>
                                                                    
                                                            </div>
                                                                </div>
                                                                {selectPaymentMode == "UPI"?
                                                                 <div className='col-lg-12'>
                                                                <div className="from-group mb-1">
                                                                    <label className="tx-gray">UPI ID</label>
                                                                   <input type="text" onChange={(e)=>setUpiId(e.target.value)}/>
                                                                   </div>
                                                                </div>:""}
                                                                </>:""}
                                                        </div>
                                                    </div>
                                                    <button type="button" className="btn btn-primary btn-lg btn-full" onClick={gotoStep2}>CONTINUE</button>
                                                </div>
                                                :
                                                <div className="d-flex justify-content-center">
                                                    <img src="/img/notallowed.webp" style={{ width: "80px" }} height="100px"></img>
                                                </div>

                                            : ""}
                                        {step == 2 ?
                                            <div className="funform">
                                                <div className="funform-inner">
                                                    <div className="funform-group">
                                                {calenderDates?
                                                        <div className='col-lg-12'>
                                                            <div className="from-group mb-1">
                                                                <label className="tx-gray">Date to deduct SIP amount</label>
                                                                <DatePicker
                                                                     style={{ width: "400px" }}
                                                                     className="custom-select"
                                                                     dateFormat="dd/MM/yyyy"
                                                                     onChange={(date) => {setStartDate(date)}}
                                                                     includeDates={defaultMandate}
                                                                     selected={startDate?startDate :enableDates}
                                                                     minDate={enableDates} 
                                                                //   maxDate={new Date(startDate.getFullYear(), startDate.getMonth(), calenderDates[calenderDates.length - 1])} 
                                                                />
                                                                {/* filterDate={filterDates} */}
                                                                {/* <select name="goal_type" className="custom-select" onChange={(e) => { setHandleMfSipDates(e.target.value) }}>
                                                                    <option value="">Select Date</option>
                                                                    {mfSipDates.map((value) => (
                                                                        <option value={value}>{addNumberSuffix(value)}</option>))}
                                                                </select> */}
                                                                <label className="tx-gray">Duration Of SIP (In Years)</label>
                                                                <select name="goal_type" className="custom-select" value={HandleMfSipDuration} onChange={(e) => { setHandleMfSipDuration(e.target.value) }}>
                                                                    <option value="">Select SIP Duration</option>
                                                                    <option value="1" >1 Year</option>
                                                                    {SIPDurationoptions}
                                                                </select>
                                                                {startDate ?
                                                                    <p>NEXT SIP : {addNumberSuffix(moment(startDate).format('DD'),)} {moment(startDate).format('MMM YYYY')} </p> : ""}
                                                            </div>
                                                        </div>:""}
                                                    </div>

                                                </div>
                                                <button type="button" className="btn btn-primary btn-lg btn-full" onClick={() => { gotoStep2() }}>CONTINUE</button>
                                            </div> : ""}
                                        {step == 3 ?
                                            <div className="funform">
                                                <div className="funform-inner" style={{ minHeight: "auto" }}>
                                                    <div className="funform-group">
                                                        <div className='col-lg-12'>
                                                            <div className="from-group mb-1">
                                                                <label className="tx-gray">Amount</label>
                                                                <CurrencyInput
                                                                    id="input-example"
                                                                    name="input-name"
                                                                    placeholder="Please Enter Amount"
                                                                    // decimalsLimit={2}
                                                                    onValueChange={(value, name) => setInputValue(value)}
                                                                    prefix="₹"
                                                                    value={inputValue}
                                                                />
                                                                <p>Min Amount : {Math.round(minAmount)}</p>
                                                                {/* <span onClick={() => { handleValueClick(500) }}>+500</span>
                                                                <span onClick={() => { handleValueClick(1000) }}>+1000</span>
                                                                <span onClick={() => { handleValueClick(2000) }}>+2000</span>
                                                                <span onClick={() => { handleValueClick(5000) }}>+5000</span> */}
                                                            </div>
                                                        </div>
                                                       
                                                    </div>

                                                </div>
                                                <button type="button" className="btn btn-primary btn-lg btn-full" onClick={gotoStep2}>CONTINUE</button>
                                            </div> : ""}

                                        {step == 4 ?
                                            <div className="funform">
                                                <div className="funform-inner">
                                                    <div className="funform-group">
                                                        <div className='col-lg-12'>
                                                            <div className="banklist" style={{ height: "auto" }}>
                                                                <div className="banklist-content">
                                                                    <ul>
                                                                        <li>Investment Amount :<span >{inputValue}</span></li>
                                                                        <li>Frequency of SIP :<span>{frequencyData}</span></li>
                                                                        
                                                                        {mutualFundDetail.sip_start_monthname ? <li>Monthly SIP deduction date :<span>{addNumberSuffix(moment(startDate).format('DD'),)} {moment(startDate).format('MMM YYYY')} </span></li> :<Skeleton />}
                                                                        <li>A/C Number :<span style={{width:"300px"}}>{accountNumber.slice(0, -4).replace(/./g, 'X')}{accountNumber.slice(-4)}({bankName})</span></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {showOTPScreen == true ?
                                                        <div className="row g-2">
                                                            <div className="col-lg-12">
                                                                <div className="from-group mb-2">
                                                                    <label htmlFor="fname">OTP<span className="tx-red">*</span></label>
                                                                    <input type="number" onChange={(e) => { setTrancOTP(e.target.value) }} />
                                                           
                                                                    {counter === 0 ? (
                                                                        <p className="tx-12 mt-2">
                                                                            Didn't receive OTP? Resend{" "}
                                                                            <span
                                                                                className="tx-theme"
                                                                                style={{ cursor: "pointer" }}
                                                                                onClick={resendOtpbr}
                                                                            >
                                                                                (Click Here)
                                                                            </span>
                                                                        </p>
                                                                    ) : (
                                                                        <p className="tx-12 mt-2">
                                                                            Resend OTP in {counter} seconds
                                                                        </p>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div> : ""}
                                                </div>

                                                {showOTPScreen == true ?
                                                    <button type="button" className="btn btn-primary btn-lg btn-full" onClick={verifyOTP} disabled={isLoading}>  {isLoading ? (
                                                        <img src="/img/loder01.gif" width="60px" height="11px" />
                                                    ) : (
                                                        "Verify OTP"
                                                    )}</button>
                                                    : <button type="button" className="btn btn-primary btn-lg btn-full" onClick={sendOTP} disabled={isLoading}> {isLoading ? (
                                                        <img src="/img/loder01.gif" width="60px" height="11px" />
                                                    ) : (
                                                        "Send OTP"
                                                    )}</button>}
                                            </div> : ""}
                                        {step == 5 ?
                                            mutualFundDetail.mf_purchase_allowed == 'Y' ?
                                                <div className="funform">
                                                    <div className="funform-inner">
                                                        <div className="funform-group">
                                                        <div className='col-lg-12'>
                                                          <div className="from-group mb-4">
                                                              <label className="tx-gray">Select Folio Number</label>
                                                              <select onChange={(e) => setFolioNumberSelect(e.target.value)}
                                                                  name="goal_type"
                                                                  className="custom-select">
                                                                  <option value=''>NEW</option>
                                                                
                                                                  {folioNumber.map((value) => (
                                                                      <option value={value.pg_folio_no}>{value.pg_folio_no}</option>
                                                                  ))}
                                                              </select>
                                                          </div>
                                                      </div>
                                                            <div className='col-lg-12'>
                                                                <div className="from-group mb-1">
                                                                    <label className="tx-gray">Bank</label>
                                                                    <select name="goal_type" className="custom-select" onChange={(e)=>checkbankdirect(e.target.value)}>
                                                                        <option value="">Select Bank</option>
                                                                        {bankData.map((value) => (
                                                                            <option value={`${value.ubd_id}_${value.bank.bse_bank_nodal}`}>{value.bank.bse_bank_name}</option>
                                                                        ))}
                                                                    </select>
                                                                    <img src="/img/upi.png" width="37px" onClick={(e) => navigate('/bank')}></img>
                                                                </div>
                                                                </div>
                                                                <div className='col-lg-12'>
                                                                <div className="from-group mb-1">
                                                                    <label className="tx-gray">Payment Mode</label>
                                                                    <select name="goal_type" className="custom-select" onChange={(e)=>setSelectPaymentMode(e.target.value)}>
                                                                        <option value="">Select payment mode</option>
                                                                   
                                                                        <option value="e-mandate">Auto debit (eNach)</option>
                                                                            <option value={bankype}>Bank</option>
                                                                            <option value="UPI">UPI</option>

                                                                            {/* <option value="NEFT">Netbanking</option> */}
                                                              
                                                                    </select>
                                                                    
                                                            </div>
                                                                </div>
                                                                {selectPaymentMode == "UPI"?
                                                                 <div className='col-lg-12'>
                                                                <div className="from-group mb-1">
                                                                    <label className="tx-gray">UPI ID</label>
                                                                   <input type="text" onChange={(e)=>setUpiId(e.target.value)}/>
                                                                   </div>
                                                                </div>:""}
                                                                {selectPaymentMode == "NEFT"?
                                                                 <div className='col-lg-12'>
                                                                <div className="from-group mb-1">
                                                                    <label className="tx-gray">NEFT REFERENCE</label>
                                                                   <input type="text" onChange={(e)=>setNeftReferene(e.target.value)}/>
                                                                </div></div>:""}
                                                                <div className='col-lg-12'>
                                                                <div className="from-group mb-1">
                                                                    <label className="tx-gray">Amount</label>
                                                                    <CurrencyInput
                                                                        id="input-example"
                                                                        name="input-name"
                                                                        placeholder="Please Enter Amount"
                                                                        // decimalsLimit={2}
                                                                        onValueChange={(value, name) => setOneTimeAmount(value)}
                                                                        prefix="₹"
                                                                        value={oneTimeAmount}
                                                                    />
                                                                    <p>Min Amount : ₹{Math.round(mutualFundDetail.mf_min_pur_amt)}</p>
                                                                    {/* <span onClick={() => { handleValueClickonetime(500) }}>+500</span>
                                                                    <span onClick={() => { handleValueClickonetime(1000) }}>+1000</span>
                                                                    <span onClick={() => { handleValueClickonetime(2000) }}>+2000</span>
                                                                    <span onClick={() => { handleValueClickonetime(5000) }}>+5000</span> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {showOneTimeOTPScreen == true ?
                                                            <div className="row g-2">
                                                                <div className="col-lg-12">
                                                                    <div className="from-group mb-2">
                                                                        <label htmlFor="fname">OTP<span className="tx-red">*</span></label>
                                                                        <input type="number" onChange={(e) => { setTrancOTP(e.target.value) }} />
                                                                        <p>{folioMobileNumber ? "OTP will send on" +" "+ folioMobileNumber:"OTP has been sent to your registered mobile number"}</p>
                                                                        {counter === 0 ? (
                                                                            <p className="tx-12 mt-2">
                                                                                Didn't receive OTP? Resend{" "}
                                                                                <span
                                                                                    className="tx-theme"
                                                                                    style={{ cursor: "pointer" }}
                                                                                    onClick={resendOtponetime}
                                                                                >
                                                                                    (Click Here)
                                                                                </span>
                                                                            </p>
                                                                        ) : (
                                                                            <p className="tx-12 mt-2">
                                                                                Resend OTP in {counter} seconds
                                                                            </p>
                                                                        )}


                                                                    </div>
                                                                </div>
                                                            </div> : ""}

                                                        {showOneTimeOTPScreen == true ?<>
                                                            <button type="button" className="btn btn-primary btn-lg btn-full" onClick={verifyOneTimeOTP} disabled={isLoading}>  {isLoading ? (
                                                                <img src="/img/loder01.gif" width="60px" height="11px" />
                                                            ) : (
                                                                "Verify OTP"
                                                            )}</button>
                                                            {/* <button type="button" className="btn btn-primary btn-lg btn-full" onClick={handleClick} disabled={isLoading}>  {isLoading ? (
                                                                <img src="/img/loder01.gif" width="60px" height="11px" />
                                                            ) : (
                                                                "confirm click"
                                                            )}</button> */}
                                                            </>
                                                            : <button type="button" className="btn btn-primary btn-lg btn-full" onClick={sendOneTimeOTP} disabled={isLoading}>  {isLoading ? (
                                                                <img src="/img/loder01.gif" width="60px" height="11px" />
                                                            ) : (
                                                                "Send OTP"
                                                            )}</button>
                                                          
                                                            }
                                                             
                                                    </div>


                                                </div> :
                                                <div className="d-flex justify-content-center">
                                                    <img src="/img/notallowed.webp" style={{ width: "80px" }} height="100px"></img>
                                                </div>
                                            : ""}
                                    </div>
                                </div>
                            </div>}
{showSkeleton == true ?
                            <div className="col-lg-6">
                                <div className="fundbox">
                                    <div className="fundbox-header">
                                    <div className="fundboxdetails">
                                                <div className="fundboxdetails-icon">
                                                    <Skeleton width="100%" height="59px" />
                                                </div>
                                                <div className="fundboxdetails-name">
                                                    <Skeleton width="300px" height="10px" />
                                                    <Skeleton width="150px" height="10px" />
                                                    {/* <div class="mfbox-details-meta"><ul><li>Very High Risk</li><li>{mutualFundDetail.mf_scheme_type}</li><li><i class="icon-star-full2 me-1"></i>5</li></ul></div>    */}
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        </div>:""}
                     
                        </div>
                    </div>
                </section>
               
                <Footer />
            </BrowserView>
            <MobileView>
                <header className="mheader">
                    <div className="container">
                        <div className="mheader-left">
                            <div className="dflex align-items-center" >
                                <i class="d-icon-arrow-left tx-22" onClick={() => { navigate("/mutual-funds") }}></i>
                                <div className="ms-3">
                                    {/* <h6 className="mb-0">{mutualFundDetail.mf_scheme_name}</h6> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                <div className="mobile-main">

                <section className="sec-pad-sm">
                    <div className="container">
                        <div className="row justify-content-center">
                {checkUserKyc == 0 ?            
                        <div className="col-lg-12 ">
                                {ubdtStatusText  ?     
                                <div class={ubdtStatusClass}>
                                    <div style={{fontSize:"13px"}}>
   <div style={{marginBottom:"-10px"}}> 
                                    
Investment Restricted due to {ubdtStatusText} Please contact us on support@goalstox.com or whatsapp on +919703071249.
</div>
<br></br>
{ubdtStatus == '02' || ubdtStatus == '03' || ubdtStatus == '04' || ubdtStatus == '05' || ubdtStatus == '06' ||kycStatus == 1 || kycStatus == 4 ?
<span  onClick={KYCprocess} style={{cursor:"pointer"}}><strong>Do KYC</strong> <i class="fas fa-arrow-alt-circle-right"></i></span>:""}
</div>

</div>:""}
                                    </div>
                           : <div className="col-lg-6">
                                <div className="fundbox">
                                    <div className="fundbox-header">
                                        
                                            <div className="fundboxdetails">

                                                <div className="fundboxdetails-icon">
                                                    <img src={mutualFundDetail.mf_image != null && mutualFundDetail.mf_image != ""? baseUrl + mutualFundDetail.mf_image : "/img/defaltimage.png"} />
                                                </div>
                                                <div className="fundboxdetails-name">
                                                    <h6>{mutualFundDetail.mf_scheme_name}</h6>
                                                    <div class="mfbox-details-meta"><ul><li>{mutualFundDetail.mf_scheme_type}</li></ul></div>
                                                </div>
                                            </div>

                                            
                                            
                                        {step !== 1 && step !== 4 && step !== 5 ? <> <div class="fundboxClose"><i class="icon-cross2" onClick={() => {
                                            setStep(1);
                                            setFrequencyData("");
                                            setHandleMfSipDates("");
                                            setHandleMfSipDuration("");
                                            setInputValue("");
                                        }}></i></div></> : ""}
                                        {step == 4 ? <> <div class="fundboxClose"><i class="icon-cross2" onClick={() => { clearLastStep() }}></i></div></> : ""}

                                    </div>
                                    <div className="fundbox-body">
                                        <div className="fundtype mb-5">
                                            <div className="fundtype-inner">
                                                <div className={changeSIPClassName == true ? "fundtypeName active" : "fundtypeName"} onClick={clickonSIP}>SIP</div>
                                                <div className={changeOneTimeClassName == true ? "fundtypeName active" : "fundtypeName"} onClick={clickonOneTime}>One Time</div>
                                            </div>
                                        </div>
                                        {step == 1 ?
                                            mutualFundDetail.mf_sip_flg == 'Y' ?
                                                <div className="funform">
                                                    <div className="funform-inner">
                                                        <div className="funform-group">

                                                            <div className='col-lg-12'>
                                                                <div className="from-group mb-1">
                                                                    <label className="tx-gray">Frequency of SIP</label>
                                                                    <select
                                                                        name="goal_type"
                                                                        className="custom-select"

                                                                        onChange={(e) => {
                                                                            const selectedValue = e.target.value;

                                                                            // Add a check to ensure selectedValue is not undefined or empty
                                                                            if (selectedValue) {
                                                                                const [mfsipId, mfsipFrequency, minAmount, maxAmount, mfsipdates] = selectedValue.split('-');
                                                                                frequencyHandleChange(mfsipId, mfsipFrequency, minAmount, maxAmount, mfsipdates);
                                                                            }
                                                                        }}
                                                                    >
                                                                        <option value={``}>SELECT FREQUENCY</option>
                                                                        {sipDetail.map((value) => (
                                                                            <option value={`${value.mfsip_frequency}-${value.mfsip_min_installment_no}-${value.mfsip_max_installmenyt_amt}-${value.mfsip_min_installmenyt_amt}-${value.mfsip_dates}`} >{value.mfsip_frequency}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-12'>
                                                            <div className="from-group mb-1">
                                                                <label className="tx-gray">Bank</label>
                                                  
                                                    <select name="goal_type" className="custom-select" onChange={(e) => checkbankdirect(e.target.value)}>
    <option value="">Select Bank</option>
    {bankData.map((value) => (
        <option 
            value={`${value.ubd_id}_${value.bank.bse_bank_nodal}_${value.bank.bse_bank_name}_${value.ubd_acc_no}_${value.created_at}_${value.ubd_mandate_status}`}
            key={value.ubd_id}
        >
            {value.bank.bse_bank_name}
        </option>
    ))}
</select>
                                                            </div>
                                                        </div>

                                                        </div>
                                                    </div>
                                                    <button type="button" className="btn btn-primary btn-lg btn-full" onClick={gotoStep2}>CONTINUE</button>
                                                </div>
                                                :
                                                <div className="d-flex justify-content-center">
                                                    <img src="/img/notallowed.webp" style={{ width: "80px" }} height="100px"></img>
                                                </div>

                                            : ""}
                                        {step == 2 ?
                                            <div className="funform">
                                                <div className="funform-inner">
                                                    <div className="funform-group">
                                                {calenderDates?
                                                        <div className='col-lg-12'>
                                                            <div className="from-group mb-1">
                                                                <label className="tx-gray">Date to deduct SIP amount</label>
                                                                <DatePicker
                                                                     style={{ width: "400px" }}
                                                                     className="custom-select"
                                                                     dateFormat="dd/MM/yyyy"
                                                                     onChange={(date) => {setStartDate(date)}}
                                                                     includeDates={defaultMandate}
                                                                     selected={startDate?startDate :enableDates}
                                                                     minDate={enableDates} 
                                                                //   maxDate={new Date(startDate.getFullYear(), startDate.getMonth(), calenderDates[calenderDates.length - 1])} 
                                                                />
                                                                {/* filterDate={filterDates} */}
                                                                {/* <select name="goal_type" className="custom-select" onChange={(e) => { setHandleMfSipDates(e.target.value) }}>
                                                                    <option value="">Select Date</option>
                                                                    {mfSipDates.map((value) => (
                                                                        <option value={value}>{addNumberSuffix(value)}</option>))}
                                                                </select> */}
                                                                <label className="tx-gray">Duration Of SIP (In Years)</label>
                                                                <select name="goal_type" className="custom-select" value={HandleMfSipDuration} onChange={(e) => { setHandleMfSipDuration(e.target.value) }}>
                                                                    <option value="">Select SIP Duration</option>
                                                                    <option value="1" >1 Year</option>
                                                                    {SIPDurationoptions}
                                                                </select>
                                                                {startDate ?
                                                                    <p>NEXT SIP : {addNumberSuffix(moment(startDate).format('DD'),)} {moment(startDate).format('MMM YYYY')} </p> : ""}
                                                            </div>
                                                        </div>:""}
                                                    </div>

                                                </div>
                                                <button type="button" className="btn btn-primary btn-lg btn-full" onClick={() => { gotoStep2() }}>CONTINUE</button>
                                            </div> : ""}
                                        {step == 3 ?
                                            <div className="funform">
                                                <div className="funform-inner" style={{ minHeight: "auto" }}>
                                                    <div className="funform-group">
                                                        <div className='col-lg-12'>
                                                            <div className="from-group mb-1">
                                                                <label className="tx-gray">Amount</label>
                                                                <CurrencyInput
                                                                    id="input-example"
                                                                    name="input-name"
                                                                    placeholder="Please Enter Amount"
                                                                    // decimalsLimit={2}
                                                                    onValueChange={(value, name) => setInputValue(value)}
                                                                    prefix="₹"
                                                                    value={inputValue}
                                                                />
                                                                <p>Min Amount : {Math.round(minAmount)}</p>
                                                                {/* <span onClick={() => { handleValueClick(500) }}>+500</span>
                                                                <span onClick={() => { handleValueClick(1000) }}>+1000</span>
                                                                <span onClick={() => { handleValueClick(2000) }}>+2000</span>
                                                                <span onClick={() => { handleValueClick(5000) }}>+5000</span> */}
                                                            </div>
                                                        </div>
                                                       
                                                    </div>

                                                </div>
                                                <button type="button" className="btn btn-primary btn-lg btn-full" onClick={gotoStep2}>CONTINUE</button>
                                            </div> : ""}

                                        {step == 4 ?
                                            <div className="funform">
                                                <div className="funform-inner">
                                                    <div className="funform-group">
                                                        <div className='col-lg-12'>
                                                            <div className="banklist" style={{ height: "auto" }}>
                                                                <div className="banklist-content">
                                                                    <ul>
                                                                        <li>Investment Amount :<span >{inputValue}</span></li>
                                                                        <li>Frequency of SIP :<span>{frequencyData}</span></li>
                                                                        
                                                                        {mutualFundDetail.sip_start_monthname ? <li>Monthly SIP deduction date :<span>{addNumberSuffix(moment(startDate).format('DD'),)} {moment(startDate).format('MMM YYYY')} </span></li> :<Skeleton />}
                                                                        <li>A/C Number :<span style={{width:"300px"}}>{accountNumber.slice(0, -4).replace(/./g, 'X')}{accountNumber.slice(-4)}({bankName})</span></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {showOTPScreen == true ?
                                                        <div className="row g-2">
                                                            <div className="col-lg-12">
                                                                <div className="from-group mb-2">
                                                                    <label htmlFor="fname">OTP<span className="tx-red">*</span></label>
                                                                    <input type="number" onChange={(e) => { setTrancOTP(e.target.value) }} />
                                                           
                                                                    {counter === 0 ? (
                                                                        <p className="tx-12 mt-2">
                                                                            Didn't receive OTP? Resend{" "}
                                                                            <span
                                                                                className="tx-theme"
                                                                                style={{ cursor: "pointer" }}
                                                                                onClick={resendOtpbr}
                                                                            >
                                                                                (Click Here)
                                                                            </span>
                                                                        </p>
                                                                    ) : (
                                                                        <p className="tx-12 mt-2">
                                                                            Resend OTP in {counter} seconds
                                                                        </p>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div> : ""}
                                                </div>

                                                {showOTPScreen == true ?
                                                    <button type="button" className="btn btn-primary btn-lg btn-full" onClick={verifyOTP} disabled={isLoading}>  {isLoading ? (
                                                        <img src="/img/loder01.gif" width="60px" height="11px" />
                                                    ) : (
                                                        "Verify OTP"
                                                    )}</button>
                                                    : <button type="button" className="btn btn-primary btn-lg btn-full" onClick={sendOTP} disabled={isLoading}> {isLoading ? (
                                                        <img src="/img/loder01.gif" width="60px" height="11px" />
                                                    ) : (
                                                        "Send OTP"
                                                    )}</button>}
                                            </div> : ""}
                                        {step == 5 ?
                                            mutualFundDetail.mf_purchase_allowed == 'Y' ?
                                                <div className="funform">
                                                    <div className="funform-inner">
                                                        <div className="funform-group">
                                                        <div className='col-lg-12'>
                                                          <div className="from-group mb-4">
                                                              <label className="tx-gray">Select Folio Number</label>
                                                              <select onChange={(e) => setFolioNumberSelect(e.target.value)}
                                                                  name="goal_type"
                                                                  className="custom-select">
                                                                  <option value=''>NEW</option>
                                                                
                                                                  {folioNumber.map((value) => (
                                                                      <option value={value.pg_folio_no}>{value.pg_folio_no}</option>
                                                                  ))}
                                                              </select>
                                                          </div>
                                                      </div>
                                                            <div className='col-lg-12'>
                                                                <div className="from-group mb-1">
                                                                    <label className="tx-gray">Bank</label>
                                                                    <select name="goal_type" className="custom-select" onChange={(e)=>checkbankdirect(e.target.value)}>
                                                                        <option value="">Select Bank</option>
                                                                        {bankData.map((value) => (
                                                                            <option value={`${value.ubd_id}_${value.bank.bse_bank_nodal}`}>{value.bank.bse_bank_name}</option>
                                                                        ))}
                                                                    </select>
                                                                    <img src="/img/upi.png" width="37px" onClick={(e) => navigate('/bank')}></img>
                                                                </div>
                                                                </div>
                                                                <div className='col-lg-12'>
                                                                <div className="from-group mb-1">
                                                                    <label className="tx-gray">Payment Mode</label>
                                                                    <select name="goal_type" className="custom-select" onChange={(e)=>setSelectPaymentMode(e.target.value)}>
                                                                        <option value="">Select payment mode</option>
                                                                   
                                                                        <option value="e-mandate">Auto debit (eNach)</option>
                                                                            <option value={bankype}>Bank</option>
                                                                            <option value="UPI">UPI</option>

                                                                            {/* <option value="NEFT">Netbanking</option> */}
                                                              
                                                                    </select>
                                                                    
                                                            </div>
                                                                </div>
                                                                {selectPaymentMode == "UPI"?
                                                                 <div className='col-lg-12'>
                                                                <div className="from-group mb-1">
                                                                    <label className="tx-gray">UPI ID</label>
                                                                   <input type="text" onChange={(e)=>setUpiId(e.target.value)}/>
                                                                   </div>
                                                                </div>:""}
                                                                {selectPaymentMode == "NEFT"?
                                                                 <div className='col-lg-12'>
                                                                <div className="from-group mb-1">
                                                                    <label className="tx-gray">NEFT REFERENCE</label>
                                                                   <input type="text" onChange={(e)=>setNeftReferene(e.target.value)}/>
                                                                </div></div>:""}
                                                                <div className='col-lg-12'>
                                                                <div className="from-group mb-1">
                                                                    <label className="tx-gray">Amount</label>
                                                                    <CurrencyInput
                                                                        id="input-example"
                                                                        name="input-name"
                                                                        placeholder="Please Enter Amount"
                                                                        // decimalsLimit={2}
                                                                        onValueChange={(value, name) => setOneTimeAmount(value)}
                                                                        prefix="₹"
                                                                        value={oneTimeAmount}
                                                                    />
                                                                    <p>Min Amount : ₹{Math.round(mutualFundDetail.mf_min_pur_amt)}</p>
                                                                    {/* <span onClick={() => { handleValueClickonetime(500) }}>+500</span>
                                                                    <span onClick={() => { handleValueClickonetime(1000) }}>+1000</span>
                                                                    <span onClick={() => { handleValueClickonetime(2000) }}>+2000</span>
                                                                    <span onClick={() => { handleValueClickonetime(5000) }}>+5000</span> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {showOneTimeOTPScreen == true ?
                                                            <div className="row g-2">
                                                                <div className="col-lg-12">
                                                                    <div className="from-group mb-2">
                                                                        <label htmlFor="fname">OTP<span className="tx-red">*</span></label>
                                                                        <input type="number" onChange={(e) => { setTrancOTP(e.target.value) }} />
                                                                        <p>{folioMobileNumber ? "OTP will send on" +" "+ folioMobileNumber:"OTP has been sent to your registered mobile number"}</p>
                                                                        {counter === 0 ? (
                                                                            <p className="tx-12 mt-2">
                                                                                Didn't receive OTP? Resend{" "}
                                                                                <span
                                                                                    className="tx-theme"
                                                                                    style={{ cursor: "pointer" }}
                                                                                    onClick={resendOtponetime}
                                                                                >
                                                                                    (Click Here)
                                                                                </span>
                                                                            </p>
                                                                        ) : (
                                                                            <p className="tx-12 mt-2">
                                                                                Resend OTP in {counter} seconds
                                                                            </p>
                                                                        )}


                                                                    </div>
                                                                </div>
                                                            </div> : ""}

                                                        {showOneTimeOTPScreen == true ?<>
                                                            <button type="button" className="btn btn-primary btn-lg btn-full" onClick={verifyOneTimeOTP} disabled={isLoading}>  {isLoading ? (
                                                                <img src="/img/loder01.gif" width="60px" height="11px" />
                                                            ) : (
                                                                "Verify OTP"
                                                            )}</button>
                                                            {/* <button type="button" className="btn btn-primary btn-lg btn-full" onClick={handleClick} disabled={isLoading}>  {isLoading ? (
                                                                <img src="/img/loder01.gif" width="60px" height="11px" />
                                                            ) : (
                                                                "confirm click"
                                                            )}</button> */}
                                                            </>
                                                            : <button type="button" className="btn btn-primary btn-lg btn-full" onClick={sendOneTimeOTP} disabled={isLoading}>  {isLoading ? (
                                                                <img src="/img/loder01.gif" width="60px" height="11px" />
                                                            ) : (
                                                                "Send OTP"
                                                            )}</button>
                                                          
                                                            }
                                                             
                                                    </div>


                                                </div> :
                                                <div className="d-flex justify-content-center">
                                                    <img src="/img/notallowed.webp" style={{ width: "80px" }} height="100px"></img>
                                                </div>
                                            : ""}
                                    </div>
                                </div>
                            </div>}

                            {showSkeleton == true ?
                            <div className="col-lg-6">
                            <div className="fundbox">
                                <div className="fundbox-header">
                             <div className="fundboxdetails">
                             <div className="fundboxdetails-icon">
                                 <Skeleton width="100%" height="59px" />
                             </div>
                             <div className="fundboxdetails-name">
                                 <Skeleton width="200px" height="10px" />
                                 <Skeleton width="150px" height="10px" />
                                 {/* <div class="mfbox-details-meta"><ul><li>Very High Risk</li><li>{mutualFundDetail.mf_scheme_type}</li><li><i class="icon-star-full2 me-1"></i>5</li></ul></div>    */}
                             </div>
                         </div>
                         </div>
                         </div>
                         </div>
                         :""}
                     
                        </div>
                    </div>
                </section>
                </div>

            </MobileView>

            {htmlData &&
                  open == true &&
                      <HtmlResponseRenderer htmlContent={htmlData} />}
                      
                      <SweetAlert success show={upiStatus}   showConfirm={false}>
                      <h4>{htmlData}</h4>
                        <p>If Payment is completed please check your status here</p>
                        <button
                            className="btn btn-primary"
                            onClick={checkupistatus}
                            disabled={isLoading}
                        >
                        {isLoading ? (<img src="/img/loder01.gif" width="60px" height="11px" />) : (
                                "Check Status"
                            )}</button>
                        <button
                            className="btn btn-secondary"
                            style={{marginLeft:'10px'}}
                            onClick={() => {
                            // Logic to go to dashboard
                            window.location.href = '/dashboard'; // example URL, adjust as needed
                            }}
                        >
                            Go to Dashboard
                        </button>
                        
            </SweetAlert>
                      <SweetAlert success show={upiSipStatus}   showConfirm={false}>
                      <h4>{htmlData}</h4>
                        <p>If Payment is completed please check your status here</p>
                        <button
                            className="btn btn-primary"
                            onClick={checksipupistatus}
                            disabled={isLoading}
                        >
                        {isLoading ? (<img src="/img/loder01.gif" width="60px" height="11px" />) : (
                                "Check Status"
                            )}</button>
                        <button
                            className="btn btn-secondary"
                            style={{marginLeft:'10px'}}
                            onClick={() => {
                            // Logic to go to dashboard
                            window.location.href = '/dashboard'; // example URL, adjust as needed
                            }}
                        >
                            Go to Dashboard
                        </button>
                        
            </SweetAlert>
          
      
            <SweetAlert danger confirmBtnCssClass='alertpop' confirmBtnText='Ok' title={matchedOTPMessage} show={warningShow} onConfirm={handlewarningOkClick}>
            </SweetAlert>
            <SweetAlert success confirmBtnCssClass='alertpop' confirmBtnText='OK' title={matchedOTPMessage} show={show} onConfirm={handleOkClick}>
            </SweetAlert>
            <SweetAlert success confirmBtnCssClass='alertpop' confirmBtnText= {isLoading ? (<img src="/img/loder01.gif" width="60px" height="11px" />) : (
                                "Complete First Payment"
                            )} title={matchedOTPMessage} show={FirstOrderShow} onConfirm={getchildorder}>
            </SweetAlert>

            
            {/* <SweetAlert success confirmBtnCssClass='alertpop' confirmBtnText='Go to order' title="Transcation in process"  show={open} onConfirm={gotoorder} >
            <div>
            <p>Transaction is in process</p>
            <p>Status can be check in orders section</p>
            <p>It can be take upto 15-30 min to update your status</p>
            </div>
            </SweetAlert> */}
        </>
    )
}

export default MutualFundsdetail