import React, { useEffect, useRef, useState } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { BrowserView, MobileView } from "react-device-detect";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { ToastContainer, toast } from 'react-toastify';
import { ApiService } from "../../Components/Services/apiServices";
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';

let apiServices = new ApiService();
function Investmentswitch() {
    const navigate = useNavigate()
    const { id } = useParams()
    const didMountRef = useRef(true)
    const [switchData, setSwitchData] = useState({})
    const [showSkeleton, setShowSkeleton] = useState(false)
    const [step, setStep] = useState()
    const [folioNumber, setFolioNumber] = useState([])
    const [FolioNumberSelect, setFolioNumberSelect] = useState("");
    const [redeemFoiloData, SetRedeemFoiloData] = useState({})
    const [RedeemType, SetRedeemType] = useState('A');
    const [RedeemVolume, SetRedeemVolume] = useState("");
    const [switchClassName, setSwitchClassName] = useState()
    const [redeemClassName, setRedeemClassName] = useState()
    const [scheemeName, setScheemeName] = useState([])
    const [newSearchSchemeName, setNewSearchSchemeName] = useState([])
    const [recomandedSchemeName, setRecomandedSchemeName] = useState([])
    const [scheemeCode, setSchemeCode] = useState('RR')
    const [schemeData, setSchemeData] = useState("")
    const [amcData, setAmcData] = useState({})
    const [amount , setAmount] = useState("")
    const [folioMobileNumber , setFolioMobileNumber] = useState("")
    const [showOtpScreen , setshowOtpScreen] = useState(false)
    const [otp ,setOtp] = useState("")
    const [counter, setCounter] = React.useState(0);
    const [isLoading, setIsLoading] = useState(false)
    const[matchedOTPMessage , setMatchedOTPMessage] = useState("")
    const [show, setShow] = useState(false)
    const [warningShow, setWarningShow] = useState(false)
    const [tempOtpId , setTempOtpId] = useState("")


    const [imageUrl, setImageUrl] = useState("")

    const Location = window.location.pathname
    useEffect(() => {
        if (didMountRef.current) {

            getSchemeNameData()
            setSwitchClassName(true)
            setShowSkeleton(true)
            const dataString = {
                id: id
            }
            apiServices.getredeemfoliospostrequest(dataString).then(res => {
                if (res.data.status == "success") {
                    setSwitchData(res.data.redeemfunddata)
                    setFolioNumber(res.data.redeemfolio)
                    if(res.data.mfdata){
                    setAmcData(res.data.mfdata)
                    }
                    setImageUrl(res.data.imgeURL)
                    setShowSkeleton(false)
                }
            })

            if (Location == "/investment-redeem/" + id) {
                setStep(4)
                setRedeemClassName(true)
                setSwitchClassName(false)
            }
            else {
                setStep(1)
            }

        }
        didMountRef.current = false;
    }, [])
    React.useEffect(() => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    }, [counter]);

    const redeemfundData = (foliono) => {
        setFolioNumberSelect(foliono);
        const dataString = {
            "folio": foliono,
            "id":id
        }
        apiServices.redeemfundDatapostrequest(dataString).then(result => {
            if (result.data.status == 'success') {
                SetRedeemFoiloData(result.data.data)
            } else if (result.data.status == "error" && result.data.message == "Session expired") {
                localStorage.removeItem('AUTH_TOKEN');
            }
            else {
                alert(result.data.message)
            }
        })
    }
    const gotostep2 = () => {
        if (FolioNumberSelect == "") {
            toast.error("Folio Number is Required")
            return false
        }
        setStep(2)
    }
    const gotostep3 = () => {
        if (scheemeCode == "") {
            toast.error("Scheme Type is Required")
            return false
        }
        if (schemeData == "") {
            toast.error("Scheme Name is Required")
            return false
        }
        setStep(3)
    }
    const getmutualFundSwitchData = (value) => {
        setSchemeCode(value)
        if (value == 'RR') {
            setScheemeName(recomandedSchemeName)
        }
        else if (value == 'NS') {
            setScheemeName(newSearchSchemeName)
        }
    }

    const switchfundata = () => {
        if(otp == ""){
            toast.error("OTP is Required")
            return false;
        }
        var redeemtype = ""
        if(RedeemType == 'A'){
            redeemtype = "ALL UNITS"
        }
        else if(RedeemType == 'U'){
            redeemtype = "UNITS"
        }
        else if(RedeemType == 'AM'){
            redeemtype = "AMOUNT"

        }
        setIsLoading(true)
        const dataString1={
            otp : otp,
            temp_otp_id : tempOtpId

          }
          apiServices.verifyotpredeemtransactionpostrequest(dataString1).then(res=>{
            if(res.data.status == "success"){

     
        const dataString = {
            mf_id: amcData.mf_id,
            folio_number: FolioNumberSelect,
            trans_to_scheme_code: schemeData,
            trans_switch_unit: RedeemType,
            trans_all_unit: RedeemVolume,
            trans_amount: amount,
            switchredeemtype : redeemtype,
            temp_otp_id : tempOtpId
        }
        apiServices.createbseSwitchorderpostrequest(dataString).then(result => {
            if (result.data.status == "success") {
                setIsLoading(false)
                setMatchedOTPMessage(result.data.message)
                setShow(true)

            }
            else if (result.data.status == "error") {
                setMatchedOTPMessage(result.data.message)
                setIsLoading(false)
                setWarningShow(true)
            }

        })
    }
    else{
        toast.error(res.data.message)  
    }
})
    }
          
    const getSchemeNameData = () => {
        const dataString = {
            pg_id: id
        }
        apiServices.getSchemeNameDataByPortfoliopostrequest(dataString).then(result => {
            if (result.data.status == "success") {
                let fund_data = result.data.recommandedData.map((items) => ({
                    value: items.mf_scheme_code,
                    label: items.mf_scheme_name
                    }));
                setScheemeName(fund_data);
                // setScheemeName(result.data.recommandedData)
                // setRecomandedSchemeName(result.data.recommandedData)
                // setNewSearchSchemeName(result.data.newSearchData)
            
            }
        })
    }



    const sendOTP=()=>{
        if (RedeemType == "") {
            toast.error("Switch is Required")
            return false
        }
        if (RedeemVolume == "" && RedeemType == "U") {
            toast.error("Units are Required")
            return false
        }
        if(amount == "" && RedeemType == "AM"){
            toast.error("Amount is Required")
            return false
        }
        if(RedeemType == 'AM' && amount < amcData.mf_min_pur_amt){
            toast.error("Please Enter Minimum Amount")
            return false
        }
        // if(amount < redeemFoiloData.totalunits*switchData.pg_nav_price && RedeemType == "AM"){
        //     toast.error("Please select minimum amount")
        //     return false
        // }
        setIsLoading(true)
        const dataString={
            folionumber : FolioNumberSelect,
            type : "Switch"
        }
        apiServices.sendotpredeemtransactionpostrequest(dataString).then(res=>{
            if(res.data.status == "success"){
                setshowOtpScreen(true)
                const maskedNumber = "X".repeat(res.data.mobilenumber.length - 4) + res.data.mobilenumber.slice(-4);
                setFolioMobileNumber(maskedNumber)
                setCounter(30);
                setIsLoading(false)
                setTempOtpId(res.data.temp_id)

            }
            else{
                toast.error(res.data.message)
                setIsLoading(false)
            }
    
        })
    }

    const resendOTP=()=>{
        setIsLoading(true)
        const dataString={
            folionumber : FolioNumberSelect,
            type : "Redeem"
        }
        apiServices.resendredeemotppostrequest(dataString).then(res=>{
            if(res.data.status == "success"){
                setCounter(30);
                setTempOtpId(res.data.temp_id)
                setIsLoading(false)

            }
            else{
                toast.error(res.data.message)
                setIsLoading(false)
            }
    
        })
    }

    const handleOkClick = () => {
        setShow(false);
        navigate("/order")
    
    }
    
    const handlewarningOkClick = () => {
        setWarningShow(false);
            window.location.reload()
    };


    return (
        <>
            <ToastContainer position={toast.POSITION.BOTTOM_CENTER} />
            <BrowserView>
                <Header />
                <div className="subheader">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h1>Investment Switch</h1>

                                <Breadcrumb>
                                    <Breadcrumb.Item href="/dashboard">Dashboard</Breadcrumb.Item>
                                    <Breadcrumb.Item active href="">Investment Switch</Breadcrumb.Item>

                                </Breadcrumb>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="sec-pad-sm">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <div className="fundbox">
                                    <div className="fundbox-header">
                                        {showSkeleton == false ?
                                            <div className="fundboxdetails">
                                                <div className="fundboxdetails-icon">
                                                    <img src={amcData.amcdata != null ? imageUrl + amcData.amcdata.amc_image : "/img/defaltimage.png"} />
                                                </div>
                                                <div className="fundboxdetails-name">
                                                    <h6>{switchData.pg_schemename}</h6>
                                                    <div class="mfbox-details-meta"><ul><li>{switchData.pg_type}</li></ul></div>
                                                </div>
                                            </div>
                                            : <div className="fundboxdetails">
                                                <div className="fundboxdetails-icon">
                                                    <Skeleton width="100%" height="59px" />
                                                </div>
                                                <div className="fundboxdetails-name">
                                                    <Skeleton width="300px" height="10px" />
                                                    <Skeleton width="150px" height="10px" />
                                                </div>
                                            </div>}
                                        {step !== 1 ? <> <div class="fundboxClose"><i class="icon-cross2" onClick={() => {
                                            setStep(1);
                                            SetRedeemType("");
                                            SetRedeemVolume("");
                                            setFolioNumberSelect("")
                                            setSchemeData("")
                                            SetRedeemFoiloData({ totalunits: "", valueatnav: "" })
    
                                        }}></i></div></> : ""}
                                        {/*  {step== 4 ?<> <div class="fundboxClose"><i class="icon-cross2" onClick={()=>{clearLastStep()}}></i></div></>:""} */}
                                    </div>
                                    <div className="fundbox-body">

                                        {step == 1 ?
                                            amcData.mf_switch_flg == 'Y' ?
                                                <div className="funform">
                                                    <div className="funform-inner">
                                                        <div className="funform-group">
                                                            <div className='col-lg-12'>
                                                                <div className="from-group mb-4">
                                                                    <label className="tx-gray">Select Folio Number</label>
                                                                    <select onChange={(e) => redeemfundData(e.target.value)}
                                                                        name="goal_type"
                                                                        className="custom-select">
                                                                        <option value={``}>SELECT FOLIO</option>

                                                                        {folioNumber.map((value) => (
                                                                            <option value={value.pg_folio_no}>{value.pg_folio_no}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <table className="table table-bordered">
                                                                    <tr>
                                                                        <th>Current Holdings</th>
                                                                        <td className=''>{redeemFoiloData.valueatnav}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Units Available</th>
                                                                        <td className=''>{redeemFoiloData.totalunits}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Last Recorded NAV*(Rs.)</th>
                                                                        <td className=''>{switchData.pg_nav_price}</td>
                                                                    </tr>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button type="button" className="btn btn-primary btn-lg btn-full" onClick={gotostep2}>CONTINUE</button>
                                                </div>
                                                : <div className="d-flex justify-content-center">
                                                    <img src="/img/notallowed.webp" style={{ width: "80px" }} height="100px"></img>
                                                </div>
                                            : ""}
                                        {step == 2 ?
                                            <div className="funform">
                                                <div className="funform-inner">
                                                    <div className="funform-group">
                                                        {/* <div className='col-lg-12'>
                                                            <div className="from-group mb-4">
                                                                <label className="tx-gray">Scheme Type</label>
                                                                <select onChange={(e) => getmutualFundSwitchData(e.target.value)}
                                                                    className="custom-select">

                                                                    <option value='RR'>Research Recomendation</option>
                                                                    <option value='NS'>New Search</option>
                                                                </select>
                                                            </div>
                                                        </div> */}
                                                        <div className='col-lg-12'>
                                                            <div className="from-group mb-4">
                                                                <label className="tx-gray">Scheme Name</label>

                                                                <Select
                                                                    // value={selectedOption}
                                                                    name="bankName"
                                                                    onChange={(e) => { setSchemeData(e.value) }}
                                                                    placeholder="Type and search for funds"
                                                                    options={scheemeName}
                                                                    />
                                                                {/* <select onChange={(e) => setSchemeData(e.target.value)}
                                                                    name="goal_type"
                                                                    className="custom-select">
                                                                    <option value="">Select Scheme Name</option>

                                                                    {scheemeName.map((key) => (
                                                                        <option value={key.mf_scheme_code}>{key.mf_scheme_name}</option>
                                                                    ))}
                                                                </select> */}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="button" className="btn btn-primary btn-lg btn-full" onClick={gotostep3}>CONTINUE</button>
                                            </div>
                                            : ""}
                                        {step == 3 ?
                                            <div className="funform">
                                                <div className="funform-inner">
                                                    <div className="funform-group">
                                                        <div className='col-lg-12'>
                                                            <div className="from-group mb-4">
                                                                <label className="tx-gray">Switch</label>
                                                                <select onChange={(e) => SetRedeemType(e.target.value)}
                                                                    className="custom-select">
                                                                    <option value='U'>Units</option>
                                                                    <option value='A' selected> Switch All</option>
                                                                    <option value='AM'>Amount</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        {RedeemType == 'U' ?
                                                            <div className='col-lg-12'>
                                                                <div className="from-group mb-4">
                                                                    <label className="tx-gray">Unit</label>
                                                                    <input type={'number'} onChange={(e) => SetRedeemVolume(e.target.value)} className="custom-select" />
                                                                </div>
                                                            </div> : ""}
                                                            {RedeemType == 'AM' ?
                                                            <div className='col-lg-12'>
                                                                <div className="from-group mb-4">
                                                                    <label className="tx-gray">Amount</label>
                                                                    <input type={'number'} onChange={(e) => setAmount(e.target.value)} className="custom-select" />
                                                                    
                                                                </div>
                                                                <p>Min Amount : ₹{Math.round(amcData.mf_min_pur_amt)}</p>
                                                            </div> : ""}
                                                            {showOtpScreen == true ?
                                                      
                                                      <div className="col-lg-12">
                                                          <div className="from-group mb-2">
                                                              <label htmlFor="fname">OTP<span className="tx-red">*</span></label>
                                                              <input type="number" onChange={(e) => { setOtp(e.target.value) }} />
                                                              <p>OTP has been sent on : {folioMobileNumber}</p>
                                                              {counter === 0 ? (
                                                                  <p className="tx-12 mt-2">
                                                                      Didn't receive OTP? Resend{" "}
                                                                      <span
                                                                          className="tx-theme"
                                                                          style={{ cursor: "pointer" }}
                                                                          onClick={resendOTP}
                                                                      >
                                                                          (Click Here)
                                                                      </span>
                                                                  </p>
                                                              ) : (
                                                                  <p className="tx-12 mt-2">
                                                                      Resend OTP in {counter} seconds
                                                                  </p>
                                                              )}
                                                          </div>
                                                      </div>
                                               : ""}
                                                    </div>
                                                </div>
                                                {showOtpScreen == true ?
                                                <button type="button" className="btn btn-primary btn-lg btn-full" onClick={switchfundata} disabled={isLoading}>  {isLoading ? (
                                                    <img src="/img/loder01.gif" width="60px" height="11px" />
                                                ) : (
                                                    "Verify OTP"
                                                )}</button>
                                                : <button type="button" className="btn btn-primary btn-lg btn-full" onClick={sendOTP} disabled={isLoading}> {isLoading ? (
                                                    <img src="/img/loder01.gif" width="60px" height="11px" />
                                                ) : (
                                                    "Send OTP"
                                                )}</button>}
                                            </div>
                                            : ""}


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </BrowserView>
            <MobileView>
                <header className="mheader">
                    <div className="container">
                        <div className="mheader-left">
                            <div className="dflex align-items-center" >
                                <i class="d-icon-arrow-left tx-22" onClick={() => { navigate("/investment") }}></i>
                                <div className="ms-3">
                                    <h6 className="mb-0">Investment Switch</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <section className="sec-pad-md">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <div className="fundbox">
                                    <div className="fundbox-header">
                                        {showSkeleton == false ?
                                            <div className="fundboxdetails">
                                                <div className="fundboxdetails-icon">
                                                    <img src={amcData.amcdata != null ? imageUrl + amcData.amcdata.amc_image : "/img/defaltimage.png"} />
                                                </div>
                                                <div className="fundboxdetails-name">
                                                    <h6>{switchData.pg_schemename}</h6>
                                                    <div class="mfbox-details-meta"><ul><li>{switchData.pg_type}</li></ul></div>
                                                </div>
                                            </div>
                                            : <div className="fundboxdetails">
                                                <div className="fundboxdetails-icon">
                                                    <Skeleton width="100%" height="59px" />
                                                </div>
                                                <div className="fundboxdetails-name">
                                                    <Skeleton width="300px" height="10px" />
                                                    <Skeleton width="150px" height="10px" />
                                                </div>
                                            </div>}
                                        {step !== 1 ? <> <div class="fundboxClose"><i class="icon-cross2" onClick={() => {
                                            setStep(1);
                                            SetRedeemType("");
                                            SetRedeemVolume("");
                                            setFolioNumberSelect("")
                                            setSchemeData("")
                                            SetRedeemFoiloData({ totalunits: "", valueatnav: "" })
    
                                        }}></i></div></> : ""}
                                        {/*  {step== 4 ?<> <div class="fundboxClose"><i class="icon-cross2" onClick={()=>{clearLastStep()}}></i></div></>:""} */}
                                    </div>
                                    <div className="fundbox-body">

                                        {step == 1 ?
                                            amcData.mf_switch_flg == 'Y' ?
                                                <div className="funform">
                                                    <div className="funform-inner">
                                                        <div className="funform-group">
                                                            <div className='col-lg-12'>
                                                                <div className="from-group mb-4">
                                                                    <label className="tx-gray">Select Folio Number</label>
                                                                    <select onChange={(e) => redeemfundData(e.target.value)}
                                                                        name="goal_type"
                                                                        className="custom-select">
                                                                        <option value={``}>SELECT FOLIO</option>

                                                                        {folioNumber.map((value) => (
                                                                            <option value={value.pg_folio_no}>{value.pg_folio_no}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <table className="table table-bordered">
                                                                    <tr>
                                                                        <th>Current Holdings</th>
                                                                        <td className=''>{redeemFoiloData.valueatnav}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Units Available</th>
                                                                        <td className=''>{redeemFoiloData.totalunits}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Last Recorded NAV*(Rs.)</th>
                                                                        <td className=''>{switchData.pg_nav_price}</td>
                                                                    </tr>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button type="button" className="btn btn-primary btn-lg btn-full" onClick={gotostep2}>CONTINUE</button>
                                                </div>
                                                : <div className="d-flex justify-content-center">
                                                    <img src="/img/notallowed.webp" style={{ width: "80px" }} height="100px"></img>
                                                </div>
                                            : ""}
                                        {step == 2 ?
                                            <div className="funform">
                                                <div className="funform-inner">
                                                    <div className="funform-group">
                                                        {/* <div className='col-lg-12'>
                                                            <div className="from-group mb-4">
                                                                <label className="tx-gray">Scheme Type</label>
                                                                <select onChange={(e) => getmutualFundSwitchData(e.target.value)}
                                                                    className="custom-select">

                                                                    <option value='RR'>Research Recomendation</option>
                                                                    <option value='NS'>New Search</option>
                                                                </select>
                                                            </div>
                                                        </div> */}
                                                        <div className='col-lg-12'>
                                                            <div className="from-group mb-4">
                                                                <label className="tx-gray">Scheme Name</label>

                                                                <Select
                                                                    // value={selectedOption}
                                                                    name="bankName"
                                                                    onChange={(e) => { setSchemeData(e.value) }}
                                                                    placeholder="Type and search for funds"
                                                                    options={scheemeName}
                                                                    />
                                                                {/* <select onChange={(e) => setSchemeData(e.target.value)}
                                                                    name="goal_type"
                                                                    className="custom-select">
                                                                    <option value="">Select Scheme Name</option>

                                                                    {scheemeName.map((key) => (
                                                                        <option value={key.mf_scheme_code}>{key.mf_scheme_name}</option>
                                                                    ))}
                                                                </select> */}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="button" className="btn btn-primary btn-lg btn-full" onClick={gotostep3}>CONTINUE</button>
                                            </div>
                                            : ""}
                                        {step == 3 ?
                                            <div className="funform">
                                                <div className="funform-inner">
                                                    <div className="funform-group">
                                                        <div className='col-lg-12'>
                                                            <div className="from-group mb-4">
                                                                <label className="tx-gray">Switch</label>
                                                                <select onChange={(e) => SetRedeemType(e.target.value)}
                                                                    className="custom-select">
                                                                    <option value='U'>Units</option>
                                                                    <option value='A' selected> Switch All</option>
                                                                    <option value='AM'>Amount</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        {RedeemType == 'U' ?
                                                            <div className='col-lg-12'>
                                                                <div className="from-group mb-4">
                                                                    <label className="tx-gray">Unit</label>
                                                                    <input type={'number'} onChange={(e) => SetRedeemVolume(e.target.value)} className="custom-select" />
                                                                </div>
                                                            </div> : ""}
                                                            {RedeemType == 'AM' ?
                                                            <div className='col-lg-12'>
                                                                <div className="from-group mb-4">
                                                                    <label className="tx-gray">Amount</label>
                                                                    <input type={'number'} onChange={(e) => setAmount(e.target.value)} className="custom-select" />
                                                                    
                                                                </div>
                                                                <p>Min Amount : ₹{Math.round(amcData.mf_min_pur_amt)}</p>
                                                            </div> : ""}
                                                            {showOtpScreen == true ?
                                                      
                                                      <div className="col-lg-12">
                                                          <div className="from-group mb-2">
                                                              <label htmlFor="fname">OTP<span className="tx-red">*</span></label>
                                                              <input type="number" onChange={(e) => { setOtp(e.target.value) }} />
                                                              <p>OTP has been sent on : {folioMobileNumber}</p>
                                                              {counter === 0 ? (
                                                                  <p className="tx-12 mt-2">
                                                                      Didn't receive OTP? Resend{" "}
                                                                      <span
                                                                          className="tx-theme"
                                                                          style={{ cursor: "pointer" }}
                                                                          onClick={resendOTP}
                                                                      >
                                                                          (Click Here)
                                                                      </span>
                                                                  </p>
                                                              ) : (
                                                                  <p className="tx-12 mt-2">
                                                                      Resend OTP in {counter} seconds
                                                                  </p>
                                                              )}
                                                          </div>
                                                      </div>
                                               : ""}
                                                    </div>
                                                </div>
                                                {showOtpScreen == true ?
                                                <button type="button" className="btn btn-primary btn-lg btn-full" onClick={switchfundata} disabled={isLoading}>  {isLoading ? (
                                                    <img src="/img/loder01.gif" width="60px" height="11px" />
                                                ) : (
                                                    "Verify OTP"
                                                )}</button>
                                                : <button type="button" className="btn btn-primary btn-lg btn-full" onClick={sendOTP} disabled={isLoading}> {isLoading ? (
                                                    <img src="/img/loder01.gif" width="60px" height="11px" />
                                                ) : (
                                                    "Send OTP"
                                                )}</button>}
                                            </div>
                                            : ""}


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </MobileView>

            <SweetAlert success confirmBtnCssClass='alertpop' confirmBtnText='OK' title={matchedOTPMessage} show={show} onConfirm={handleOkClick}>
            </SweetAlert>
            <SweetAlert danger confirmBtnCssClass='alertpop' confirmBtnText='Ok' title={matchedOTPMessage} show={warningShow} onConfirm={handlewarningOkClick}>
            </SweetAlert>

        </>
    )
}

export default Investmentswitch