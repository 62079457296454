import React, { useEffect, useRef, useState } from "react";
import Header from "../../Components/Header"
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { ApiService } from "../../Components/Services/apiServices";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Dropdown from 'react-bootstrap/Dropdown';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import { BrowserView, MobileView } from "react-device-detect";
import Modal from 'react-bootstrap/Modal';
let apiServices = new ApiService();
function Bank() {
  const [userBankData, setUserBankData] = useState([])
  const [bankData, setBanksData] = useState([])
  const [deletebank, setdeletebank] = useState(false)
  const [ubdid, setubdid] = useState("")
  const [primubdid, setprimubdid] = useState("")
  const [addBankStep1, setAddBankStep1] = useState(false)
  const [addBankStep2, setAddBankStep2] = useState(false)
  const [addBankStep3, setAddBankStep3] = useState(false)
  const [bankdetail, setbankdetail] = useState("")
  const [primaryalert, setprimaryalert] = useState(false)
  const [showskeleton, setshowskeleton] = useState(false)
  const [imgUrl, setImgUrl] = useState("")
  const [userPrimaryBankData, setUserPrimaryBankData] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [isHovered, setIsHovered] = useState(false);
  const [mandateAlert, setMandateAlert] = useState(false)
  const [showOTPScreen, setShowOTPScreen] = useState("")
  const [OTP, setOTP] = useState("")
  const [counter, setCounter] = React.useState(0);
  const [showmodal, setShowmodal] = useState(false);
  const [otpData, setOtpData] = useState("");
  const [seconds, setseconds] = useState(0);
  const [minutes, setminutes] = useState(10);
  const [bankDetails1, setBankDetails1] = useState(
    {
      accType: '',
      accNo: '',
      neftIfscCode: '',
      micrNo: '',
      bankName: '',
      bankBranch: '',
    },

  );
  const handlemodalclose = () => setShowmodal(false)

  const navigate = useNavigate()
  const didMountRef = useRef(true)
  useEffect(() => {
    if (didMountRef.current) {


      setshowskeleton(true)
      apiServices.getuserbankdataPostRequest({}).then((result) => {
        if (result.data.status === 'success') {

          setUserBankData(result.data.bse_bank_data)
          setImgUrl(result?.data?.imgeURL)
          setUserPrimaryBankData(result.data.userPrimaryBankData)
          setshowskeleton(false)
        } else if (result.data.status === "error" && result.data.message === "Session expired") {
          navigate("/");
          localStorage.removeItem('AUTH_TOKEN');
          setshowskeleton(false)
        }
        else {
          setshowskeleton(false)
        }
      })
        .catch((error) => {
        });
      getbanksData()
      if (userBankData == " " && userPrimaryBankData === " "){
        setTimeout(() => {
          setAddBankStep1(true)
        }, 1000)
      }
      // didMountRef.current = true;
    }
    didMountRef.current = false;
  }, [userPrimaryBankData, userBankData]);

  const Confirm = () => {
    setShowmodal(true)
    sendOTP('delete')
    setdeletebank(false)
  }
  const deletebankiferror = (id) => {
    if (id) {
      let datastring = {
        ubd_id: id
      }
      apiServices.deletebankaccount(datastring).then((result) => {

        if (result.data.status == "success") {
          
         

        }
        else {

        }
      })
    }

  }

  const deletebsebankaccountfromDB = (id) => {
    if (id) {
      let datastring = {
        ubd_id: id
      }
      apiServices.deletebsebankaccountfromDB(datastring).then((result) => {
      })
    }

  }

  const Cancel = () => {
    setdeletebank(false)
  }
  const getbankdata = () => {
    setshowskeleton(true)
    apiServices.getuserbankdataPostRequest({}).then((result) => {
      if (result.data.status === 'success') {
        setUserBankData(result.data.bse_bank_data)
        setImgUrl(result?.data?.imgeURL)
        setshowskeleton(false)
        setUserPrimaryBankData(result.data.userPrimaryBankData)
      } else if (result.data.status === "error" && result.data.message === "Session expired") {
        navigate("/");
        localStorage.removeItem('AUTH_TOKEN');
        setshowskeleton(false)
      }
    })
  }
  const Confirmprim = () => {
    if (primubdid) {
      let datastring = {
        ubd_id: primubdid
      }
      apiServices.makebankprimary(datastring).then((result) => {

        if (result.data.status == "success") {
          toast.success("Your Primary bank has been changed successfully")
          getbankdata()
          setprimaryalert(false)
        }
        else {
          toast.error(result.data.message);
          setprimaryalert(false)
        }
      })
    }

  }
  const Cancelprim = () => {
    setprimaryalert(false)
  }
  const getbanksData = () => {
    apiServices.getbsebanksGetRequest().then((result) => {
      if (result.data.status === "success") {
        let data = result.data?.data ? result.data?.data : [];
        let imgurl = result?.data?.imgeURL;
        let bank_data = data.map((items) => ({
          value: items.bse_b_id,
          label: (
            <div>
              <img
                className="me-4"
                src={
                  items?.bse_bank_img && imgurl
                    ? imgurl + items?.bse_bank_img
                    : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4bZWRAQW68fi5U7P6YTKYicX4smRbw0mRuw&usqp=CAU"
                }
                height="40px"
                width="40px"
              />
              {items.bse_bank_name}
            </div>
          )
        }));
        setBanksData(bank_data);
      }
    });
  };

  const customFilterOption = (option, searchText) => {

    if (!searchText) return true;
    const text = searchText.toLowerCase();
    const labelChildren = React.Children.toArray(option.label.props.children);
    const bankName = labelChildren.filter(child => typeof child === 'string').join('').toLowerCase();
    return bankName.includes(text);
  };
  const handleInputChange1 = (e) => {
    const { name, value } = e.target;
    setBankDetails1({ ...bankDetails1, [name]: value });
  };

  const AddBank = () => {
    setAddBankStep1(true)
  }
  const handleNextClick = (e) => {

    if (e.target.value.length === 11) {
      const regex = new RegExp("^[A-Z]{4}0[A-Z0-9]{6}$")
      if (e.target.value.match(regex)) {
        const dataString = {
          bankName: bankDetails1.bankName,
          neftIfscCode: e.target.value,
          // bankBranch: bankDetails1.bankBranch
        }
        apiServices.ifscdataPostRequest(dataString).then(res => {
          if (res.status === 200 && res.data.ifsc && res.data.ifsc.bse_b_id) {
            apiServices.getMICRGetRequest(e.target.value).then(result => {

              setbankdetail({
                ...bankdetail,
                ['bankName']: res.data.ifsc.bse_bank_name,
                ["bankimg"]: res?.data?.ifsc?.bse_bank_img,
                ['micrNo']: result.data.MICR,
                ["Ifsccode"]: result.data.IFSC,
                ["bankBranch"]: result.data.BRANCH
                // ["branchName"]: result.data.BRANCH

              });
              // setAddBankStep2(false)
              //   setAddBankStep3(true)
              if (res.data.ifsc.bse_b_id !== bankDetails1.bankName) {

                toast.error("IFSC code do not match with selected bank")
                return;
              }
              else {
                setAddBankStep2(false)
                setAddBankStep3(true)
              }

            })
          }
          else if (res?.status == "error") {
            toast.error(res?.message, { position: 'top-right' });
            return true
            // setBankDetailsFirst({...bankDetailsfirst,  [name]: value});
          }
        })
      }

      else {
        toast.error("IFSC code is not correct")
        return true
      }
    }

  }


  const handlechangebank = (e) => {
    if (bankDetails1.bankName == "" || bankDetails1.bankName == null) {
      toast.error("Please select your required bank ")
      return true;
    }
    else {
      setAddBankStep1(false)
      setAddBankStep2(true)
    }

  }

  const Closeaddbank = () => {
    setAddBankStep2(false);
    setAddBankStep1(false)
    setAddBankStep3(false);
    setBankDetails1({ ...bankDetails1, accType: "", accNo: "", neftIfscCode: "", micrNo: "", bankName: "", bankBranch: "" })

  }

  const sendOTP = (type) => {

    if(type !== 'delete'){
    if (!bankDetails1.accNo) {
      toast.error('Please enter the account number for Bank');
      return;
    }
    // if (bankDetails1.accNo.length <12 ||bankDetails1.accNo.length >12 ) {
    //   toast.error('Bank Account Number must be of 12 digits ');
    //   return;
    // }
    if (!bankDetails1.accType) {
      toast.error('Please enter the account type for Bank');
      return;
    }
  }
    setIsLoading(true)
    const dataString = {
      type: "SIP"
    }
    apiServices.sendotpbankpostrequest(dataString).then((result) => {
      if (result.data.status == "success") {

        setIsLoading(false)
        setCounter(30);
        setShowOTPScreen(true)
      }
      else {
        setIsLoading(false)
        toast.error(result.data.message)
      }
    })
  }

  React.useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  const verifyOTP = () => {
    setIsLoading(true)
    const dataString = {
      otp: OTP
    }
    apiServices.verifyotpbankpostrequest(dataString).then((result) => {
      if (result.data.status == "success") {

        const dataString1 = {
          accType: bankDetails1.accType,
          accNo: bankDetails1.accNo,
          neftIfscCode: bankDetails1.neftIfscCode,
          bankName: bankDetails1.bankName,
          bankBranch: bankdetail.bankBranch,
          micrNo: bankdetail?.micrNo

        }
        apiServices.bseaddbankPostRequest(dataString1).then(result => {
          if (result.data.status === 'success') {
            // setubdid(result.data.id)
            console.log(result.data.id, "id")

            // toast(result.data.message, {
            //   position: toast.POSITION.TOP_CENTER
            // });
            // setTimeout(() => {
            //   window.location.reload()
            // }, 2000)
            // setIsLoading(false)

            const newdatastring = {
              type: "MOD",
              mod_id : result.data.mod_id
            }
            apiServices.clientregitrationPostRequest(newdatastring).then((response) => {
              if (response.data.status == "success") {
                setIsLoading(false)
                toast.success("Bank added successfully", {
                  position: toast.POSITION.TOP_CENTER
                })
                setTimeout(() => {
                  window.location.reload()
                }, 2000)


              } else if (response?.data?.status == "error") {
                deletebsebankaccountfromDB(result.data.id)
                toast.error(response?.data?.message)
                setIsLoading(false)
              }

              else {
                deletebsebankaccountfromDB(result.data.id)
                toast.error(response?.data?.data?.ResponseString)
                setIsLoading(false)
              }
            })


          } else {
            toast(result.data.message, {
              position: toast.POSITION.TOP_CENTER
            });
            setTimeout(() => {
              window.location.reload()
            }, 2000);
            setIsLoading(false)
          }
        })


      }

      else if (result.data.status == "error") {
        toast.error(result.data.message)
        setIsLoading(false)
      }

    })
  }
  const resendOtp = () => {
    setIsLoading(true)
    const dataString = {

    }
    apiServices.resendotpbankpostrequest(dataString).then(res => {
      if (res.data.status == 'success') {
        setCounter(30);
        setminutes(10);
        setseconds(0);
        setIsLoading(false)
        return false;
      }
      else {
        toast(res.data.message);
        return false;
      }
    })
  }
  React.useEffect(() => {
    setTimeout(() => {
      if (seconds > 0) {
        setseconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
        } else {
          setseconds(59);
          setminutes(minutes - 1);
        }
      }
    }, 1000);
  });

  const verifyotpdelete = () => {
    setIsLoading(true)
    const dataString = {
      otp: otpData
    }
    apiServices.verifyotpbankpostrequest(dataString).then((result) => {
      if (result.data.status == "success") {

        if (ubdid) {
          let datastring = {
            ubd_id: ubdid,
             type: "MOD",
          }
                  apiServices.deletebankaccount(datastring).then((result) => {

                    if (result.data.status == "success") {
                  setIsLoading(false)
                  setdeletebank(false)
                  getbankdata()
                
                  toast.success(result.data.message, {
                    position: toast.POSITION.TOP_CENTER
                  })
                  setTimeout(() => {
                    window.location.reload()
                  }, 2000)
      

                }
                else {
                  toast.error(result.data.message);
                  setdeletebank(false)
                  setTimeout(() => {
                    window.location.reload()
                  }, 2000)
      
                }
              })
            }           
          
        }
      
      else {
        toast(result.data.message, {
          position: toast.POSITION.TOP_CENTER
        });
        setTimeout(() => {
          window.location.reload()
        }, 2000);
        setIsLoading(false)
      }

    })
  }
  const onConfirmSA = (id) => {
    const dataString = {
      ubd_id: id,
    }
    apiServices.createmandateauthlinkpostrequest(dataString)
      .then(result => {
        console.log('result.data',result.data);
        if (result.data.status == "success") {
          window.open(result.data.link)
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        }
        else {
          toast.error(result?.data?.message)
          setIsLoading(false)
        }
      })
  }

  const getCurrentDate = () => {
    const date = new Date();
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options); // Using 'en-GB' for DD/MM/YYYY format
  };

  return (
    <>
      <ToastContainer position={toast.POSITION.BOTTOM_CENTER} autoClose={3000} />
      <BrowserView>
        <Header />
        <div className="subheader">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h1>Bank Account Details</h1>
                <Breadcrumb>
                  <Breadcrumb.Item href="/dashboard">Dashboard</Breadcrumb.Item>
                  <Breadcrumb.Item active>Add Bank</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
          </div>
        </div>

        <section className="sec-pad-sm">
          <div className="container">
            <div className={`row g-3 ${addBankStep1 || addBankStep2 || addBankStep3 ? "d-none" : ""}`}>

              {
                !addBankStep1 && !addBankStep2 && !addBankStep3 && <>
                  {showskeleton === false && userPrimaryBankData ? (
                    <div className="col-lg-4">
                      <div className="banklist">
                        <div className="banklist-header">
                          <div className="dflex align-items-center">
                            <div className="banklist-icon">
                              <img src={userPrimaryBankData?.bank?.bse_bank_img ? imgUrl + userPrimaryBankData?.bank?.bse_bank_img : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4bZWRAQW68fi5U7P6YTKYicX4smRbw0mRuw&usqp=CAU"}></img>

                            </div>
                            <div className="banklist-name ms-3">
                              <h6 className="mb-0 tx-uppercase"> {userPrimaryBankData?.bank?.bse_bank_name}</h6>
                              <p className="mb-0 tx-green" >Primary Bank</p>
                            </div>

                          </div>
                          {/* <Dropdown className="morebutton ml-auto">
                            <Dropdown.Toggle id="dropdown-basic" className="">
                              <i class="icon-more2"></i>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item href="" onClick={() => { setdeletebank(true); setubdid(userPrimaryBankData?.ubd_id) }}>Delete</Dropdown.Item>

                             
                            </Dropdown.Menu>
                          </Dropdown> */}

                        </div>
                        {/* {userPrimaryBankData.ubd_mandate_status == 0 ? <div className="ifsc-verify"><img src="/img/pending.png" className="wd-20"></img>Bank Mandate Pending<img src="/img/information.png"  className="hover-button"
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)} width="60px"></img></div> : ""}
                        {userPrimaryBankData.ubd_mandate_status == 1 ? <div className="ifsc-verify"><img src="/img/check.png" className="wd-20"></img>Bank Mandate Approve</div> : ""}
                        {userPrimaryBankData.ubd_api_mandateid == null ? <div className="ifsc-verify">Bank Mandate </div> : ""} */}


                        {isHovered && <span className="hover-text">{userPrimaryBankData.ubd_api_statustext}</span>}
                        <div className="banklist-content">
                          <ul>
                            <li><span>Account</span>{userPrimaryBankData?.ubd_acc_no}
                            </li>
                            <li><span>IFSC Code</span> {userPrimaryBankData?.ubd_ifsc}</li>
                            <li><span>Branch Name</span> {userPrimaryBankData?.ubd_bank_branch}</li>
                          </ul>
                          {userPrimaryBankData.ubd_api_mandateid !== null ? "" : <>
                            <hr></hr>
                            <div className="text-center">
                              <a href="#" className="btn btn-outline-primary btn-md" onClick={() => { navigate(`/bankmandate/${userPrimaryBankData?.ubd_id}`) }}>SETUP AUTOPAY</a>
                            </div></>}
                          {userPrimaryBankData.ubd_api_mandateid !== null ? <>
                            <hr></hr>
                            <h6>Active Autopay mandates</h6>
                            <hr></hr>
                            <ul>
                              <li><span>Mandates Id</span>{userPrimaryBankData.ubd_api_mandateid}</li>
                              <li><span>Status</span>{userPrimaryBankData.ubd_mandate_status == 0 ? <div className="text-danger">Incomplete<span><img src="/img/refresh.jpg" onClick={()=> {window.location.reload()}} style={{height:'15px', width:'15px', marginLeft:'5px'}}/></span></div> : ""}{userPrimaryBankData.ubd_mandate_status == 1 ? <div className="text-success">Approved</div> : ""}
                                {userPrimaryBankData.ubd_mandate_status == 2 ? <div className="text-danger">Reject</div> : ""} {userPrimaryBankData.ubd_mandate_status == 3 ? <div className="text-warning">Under Processing</div> : ""}</li>
                                {userPrimaryBankData.ubd_max_amount ?<li><span>Mandate Amount</span>{userPrimaryBankData.ubd_max_amount}</li>:""}
                              <li><span>Created on</span>{userPrimaryBankData.ubd_mandate_startdate}</li>
                            </ul>


                          </> : ""}
                          {userPrimaryBankData.ubd_api_mandateid !== null && userPrimaryBankData.ubd_mandate_status == 0 ? <>
                            <hr></hr>
                            <div className="text-center">
                              <p>If the status above does not change, you can try again in 3 miniutes. </p>
                              <a href="#" className="btn btn-outline-primary btn-md" onClick={() => { onConfirmSA(userPrimaryBankData?.ubd_id)}}>Try Again</a>

                            </div></> : ''}
                          {userPrimaryBankData.ubd_mandate_status == 2 ?
                            <div className="banklist-content">
                              <div class="alert alert-warning mb-0 mt-2 tx-13" role="alert">Complete bank mandates to automate monthly SIP payments. <a href="#" onClick={() => { navigate(`/bankmandate/${userPrimaryBankData?.ubd_id}`) }}>Setup Autopay</a></div></div> : ""}
                        </div>
                      </div>
                    </div>
                  ) : (

                    <div class="col-lg-4">
                      <div class="banklist">
                        <div class="banklist-header">
                          <div class="dflex align-items-center">
                            <div class="banklist-icon"><Skeleton width="40px" height="40px" /></div>
                            <div class="banklist-name ms-3">
                              <h6 class="mb-0 tx-uppercase"><Skeleton width="100px" height="10px" /></h6>
                              <p class="mb-0 tx-green"><Skeleton width="80px" height="10px" /></p>
                            </div>
                          </div>
                          <div class="morebutton ml-auto dropdown">
                            <button type="button" id="dropdown-basic" aria-expanded="false" class="dropdown-toggle btn btn-primary"><i class="icon-more2"></i></button>
                          </div>
                        </div>
                        <div class="banklist-content">
                          <ul>
                            <li><span>Account</span><Skeleton width="80px" height="10px" /></li>
                            <li><span>IFSC Code</span> <Skeleton width="80px" height="10px" /></li>
                            <li><span>Branch Name</span><Skeleton width="80px" height="10px" /></li>
                          </ul>
                          <hr />
                          <h6>Active Autopay mandates</h6>
                          <hr />
                          <ul>
                            <li><span>Mandates Id</span><Skeleton width="80px" height="10px" /></li>
                            <li>
                              <span>Status</span>
                              <div class="text-danger"><Skeleton width="80px" height="10px" /></div>
                            </li>
                            <li><span>Created on</span><Skeleton width="80px" height="10px" /></li>
                          </ul>
                        </div>
                      </div>
                    </div>

                  )}

                  {showskeleton == false ? <>
                    {userBankData && userBankData?.map((value) => {

                      return (<>
                        <div className="col-lg-4">
                          <div className="banklist">
                            <div className="banklist-header">
                              <div className="dflex align-items-center">
                                <div className="banklist-icon">
                                  <img src={value?.bank?.bse_bank_img ? imgUrl + value?.bank?.bse_bank_img : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4bZWRAQW68fi5U7P6YTKYicX4smRbw0mRuw&usqp=CAU"}></img>
                                </div>
                                <div className="banklist-name ms-3">
                                  <h6 className="mb-0 tx-uppercase">{value?.bank?.bse_bank_name}</h6>
                                  {/* <p className="mb-0 tx-green">Primary Bank</p> */}
                                </div>
                              </div>
                              <Dropdown className="morebutton ml-auto">
                                <Dropdown.Toggle id="dropdown-basic" className="">
                                  <i class="icon-more2"></i>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                {value.ubd_mandate_status == 0 ?
                              <Dropdown.Item href="#/action-1" onClick={() => { setdeletebank(true); setubdid(value?.ubd_id) }}>Delete</Dropdown.Item>:''}
                                  <Dropdown.Item href="#/action-2" onClick={() => { if (value.ubd_api_mandateid == null) { setMandateAlert(true) } else { setprimaryalert(true); } setprimubdid(value?.ubd_id) }}>Make Primary</Dropdown.Item>
                                  {/* <Dropdown.Item onClick={() => { navigate(`/bankmandate/${value?.ubd_id}`) }}>Bank Autopay</Dropdown.Item>
                                <Dropdown.Item onClick={() => getmandatestatus(value?.ubd_api_statustext, value?.ubd_mandate_status)} >Check Mandate Status </Dropdown.Item> */}
                                </Dropdown.Menu>
                              </Dropdown>

                            </div>
                            {/* {value.ubd_api_mandateid == null ? <div className="ifsc-verify">Please do mandate </div> : ""} */}
                            <div className="banklist-content">
                              <ul>
                                <li><span>Account</span>{value.ubd_acc_no}</li>
                                <li><span>IFSC Code</span>{value?.ubd_ifsc}</li>
                                <li><span>Branch Name</span>{value?.ubd_bank_branch}</li>
                              </ul>
                              {value.ubd_api_mandateid !== null || value.ubd_mandate_status == 2 ? "" : <>
                                <hr></hr>
                                <div className="text-center">
                                  <a href="#" className="btn btn-outline-primary btn-md" onClick={() => { navigate(`/bankmandate/${value?.ubd_id}`) }}>SETUP AUTOPAY</a>
                                </div></>}
                              {value.ubd_api_mandateid !== null ? <>
                                <hr></hr>
                                <h6>Active Autopay mandates</h6>
                                <hr></hr>
                                <ul>
                                  <li><span>Mandates Id</span>{value.ubd_api_mandateid}</li>
                                  <li><span>Status</span>{value.ubd_mandate_status == 0 ? <div className="text-danger">Incomplete<span><img src="/img/refresh.jpg" onClick={()=> {window.location.reload()}} style={{height:'15px', width:'15px', marginLeft:'5px'}}/></span></div> : ""}{value.ubd_mandate_status == 1 ? <div className="text-success">Approved</div> : ""}
                                  {value.ubd_mandate_status == 2 ? <div className="text-danger">Reject</div> : ""} {value.ubd_mandate_status == 3 ? <div className="text-warning">Under Processing</div> : ""}</li>
                                  {value.ubd_max_amount ?<li><span>Mandate Amount</span>{value.ubd_max_amount}</li>:""}
                                  <li><span>Created on</span>{value.ubd_mandate_startdate}</li>
                                </ul>
                              </> : ""}
                              {value.ubd_api_mandateid !== null && value.ubd_mandate_status == 0 ? <>
                            <hr></hr>
                            <div className="text-center">
                            <p>If the status above does not change, you can try again in 3 miniutes. </p>
                              <a href="#" className="btn btn-outline-primary btn-md" onClick={() => { onConfirmSA(value?.ubd_id)}}>Try Again</a>
                            </div></> : ''}
                              {mandateAlert == true && primubdid == value.ubd_id || value.ubd_mandate_status == 2 ?

                                <div class="alert alert-warning mb-0 mt-2 tx-13" role="alert">Complete bank mandates to automate monthly SIP payments. <a onClick={() => { navigate(`/bankmandate/${value?.ubd_id}`) }}>Setup Autopay</a></div> : ""}
                            </div>
                          </div>

                        </div>
                      </>)


                    })}
                  </> : <>
                    <div class="col-lg-4">
                      <div class="banklist">
                        <div class="banklist-header">
                          <div class="dflex align-items-center">
                            <div class="banklist-icon"><Skeleton width="40px" height="40px" /></div>
                            <div class="banklist-name ms-3"><h6 class="mb-0 tx-uppercase"><Skeleton width="100px" height="10px" /></h6></div>
                          </div>
                          <div class="morebutton ml-auto dropdown">
                            <button type="button" id="dropdown-basic" aria-expanded="false" class="dropdown-toggle btn btn-primary"><i class="icon-more2"></i></button>
                          </div>
                        </div>
                        <div class="banklist-content">
                          <ul>
                            <li><span>Account</span><Skeleton width="80px" height="10px" /></li>
                            <li><span>IFSC Code</span> <Skeleton width="80px" height="10px" /></li>
                            <li><span>Branch Name</span><Skeleton width="80px" height="10px" /></li>
                          </ul>
                          <hr />
                          <div class="text-center"><a href="#" class="btn btn-outline-primary btn-md">SETUP AUTOPAY</a></div>
                        </div>
                      </div>
                    </div>


                  </>}


                  {userBankData.length < 2 ? <>

                    <div className="col-lg-4 ">
                      <div className="addbanklist " onClick={AddBank}>
                        <img src="img/plus.png" className="wd-42 mb-2"></img>
                        <p className="mb-0 tx-theme fw700">Add Another Bank</p>
                      </div>
                    </div>
                  </> : ""}



                </>
              }
            </div>
            <div className="row align-items-center justify-content-center">
              {addBankStep1 === true && <>
                <div className="col-lg-6">
                  <div className="bankdropdownbx">
                    <div className="bankdropdownbxClose" onClick={() => { Closeaddbank() }}>
                      <i class="icon-cross2"></i>
                    </div>
                    <h6>Choose Bank</h6>
                    <div className="from-group bankinput">
                      <label className="tx-gray">Bank is compulsory for openning an investment account</label>
                      <Select
                        name="bankName"
                        onChange={(selectedOption) => {
                          setBankDetails1({ ...bankDetails1, bankName: selectedOption.value });
                          console.log(selectedOption.value);
                        }}
                        placeholder="Type and search for bank"
                        options={bankData}
                        filterOption={customFilterOption}
                      />

                    </div>

                    <button className="btn btn-primary mt-4 btn-lg btn-full" type="button" onClick={(e) => handlechangebank(e)}>
                      Next
                    </button>

                  </div>
                </div>

              </>}
              {addBankStep2 === true && <>
                <div className="col-lg-6">
                  <div className="bankdropdownbx ">
                    <div className="bankdropdownbxClose" onClick={() => { Closeaddbank() }}>
                      <i class="icon-cross2"></i>
                    </div>
                    <h6>Bank IFSC Code</h6>

                    <div className="from-group">
                      <label className=" tx-gray">Enter your IFSC number for</label>
                      <input className="w-100 mb-3" placeholder="Enter your IFSC code" type=""
                        name="neftIfscCode"
                        id="neftIfscCode"
                        // defaultValue={bankDetails1.neftIfscCode}
                        onChange={(e) => { handleInputChange1(e); handleNextClick(e) }}
                        onInput={(e) => e.target.value = e.target.value.toUpperCase()}
                        minLength="11"
                        maxLength="11"
                        pattern="^[A-Z]{4}0[A-Z0-9]{6}$"
                      >

                      </input>
                    </div>
                    {/* <div>
                    <button className="btn btn-primary btn-lg btn-full" type="number" onClick={handleNextClick}>
                      Next
                    </button>
                  </div> */}

                  </div>
                </div>
              </>}
              {addBankStep3 === true && <>
                <div className="col-lg-6">
                  <div className="bankdropdownbx ">
                    <div>
                      <div className="bankdropdownbxClose" onClick={() => { Closeaddbank() }}>
                        <i class="icon-cross2"></i>
                      </div>
                      <h6>Verify your Bank Account</h6>

                      <p className="tx-gray">Investments on Goalstox can be done using only saving bank accounts</p>
                    </div>
                    <div className="banklist">
                      <div className="banklist-header justify-content-between align-items-start">
                        <div className="dflex align-items-center">
                          <div className="banklist-icon">
                            <img src={bankdetail?.bankimg ? imgUrl + bankdetail?.bankimg : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4bZWRAQW68fi5U7P6YTKYicX4smRbw0mRuw&usqp=CAU"}></img>
                          </div>
                          <div className="banklist-name ms-3">
                            <h6 className="mb-0 tx-uppercase">{bankdetail?.bankName}</h6>

                          </div>
                        </div>
                        {/* <p className="tx-green">Edit</p> */}
                      </div>
                      <div className="banklist-content">
                        <ul>
                          <li><span>Bank Name</span>{bankdetail?.bankName}</li>
                          <li><span>IFSC Code</span> {bankdetail?.Ifsccode}</li>
                          <li><span>Bank Branch</span> {bankdetail?.bankBranch}</li>
                        </ul>
                      </div>
                    </div>
                    <div className="mt-4 from-group">
                      <label className=" tx-gray">Enter Bank Account Number </label>
                      <input className="w-100 mb-3" placeholder="Eg : 134567876545" name="accNo" type="number" minLength="12"
                        maxLength="12" onChange={(e) => { handleInputChange1(e) }}></input>
                      <label className=" tx-gray">Select Bank Type </label>
                      <select
                        name="accType"
                        id="accType"
                        onChange={(e) => { handleInputChange1(e) }}
                        className="custom-select"
                      >
                        <option value="">Select bank type</option>
                        <option value="SB" >Savings Bank</option>
                        <option value="CB" >Current Bank</option>
                        <option value="NE" >NRE</option>
                        <option value="NO" >NRO</option>
                      </select>
                      {/* <label className=" tx-gray mt-3">Enter Branch Name </label>
                  <input className="w-100 mb-3" placeholder="Branch name" name="bankBranch" value={bankdetail?.bankBranch} disabled={bankdetail?.bankBranch?true:""} readOnly onChange={(e) => { handleInputChange1(e) }}></input> */}
                    </div>
                    <div>
                      {showOTPScreen == true ?
                        <div className="row g-2">
                          <div className="col-lg-12">
                            <div className="from-group mb-2">
                              <label htmlFor="fname">OTP<span className="tx-red">*</span></label>
                              <input type="number" onChange={(e) => { setOTP(e.target.value) }} />

                              {counter === 0 ? (
                                <p className="tx-12 mt-2">
                                  Didn't receive OTP? Resend{" "}
                                  <span
                                    className="tx-theme"
                                    style={{ cursor: "pointer" }}
                                    onClick={resendOtp}
                                  >
                                    (Click Here)
                                  </span>
                                </p>
                              ) : (
                                <p className="tx-12 mt-2">
                                  Resend OTP in {counter} seconds
                                </p>
                              )}


                            </div>
                          </div>
                        </div> : ""}
                      {showOTPScreen == true ?
                        <button type="button" className="btn btn-primary btn-lg btn-full mt-4" disabled={isLoading} onClick={verifyOTP}>  {isLoading ? (
                          <img src="/img/loder01.gif" width="60px" height="11px" />
                        ) : (
                          "Verify OTP"
                        )}</button>
                        : <button type="button" className="btn btn-primary btn-lg btn-full mt-4" onClick={()=>{sendOTP('new')}} disabled={isLoading}>  {isLoading ? (
                          <img src="/img/loder01.gif" width="60px" height="11px" />
                        ) : (
                          "Send OTP"
                        )}</button>

                      }
                      {/* <button className="btn btn-primary btn-lg btn-full mt-4" type="number" onClick={handleVerifyNextClick} disabled={isLoading}>
                        {isLoading ? (
                          <img src="/img/loder01.gif" width="60px" height="11px" />
                        ) : (
                          "Verify Bank"
                        )}
                      </button> */}
                    </div>

                  </div>
                </div>
              </>}
            </div>
          </div>
        </section>
      </BrowserView>
      <MobileView>
        <header className="mheader">
          <div className="container">
            <div className="mheader-left">
              <div className="dflex align-items-center" >
                <i class="d-icon-arrow-left tx-22" onClick={() => { navigate("/my-account") }}></i>
                <div className="ms-3">
                  <h6 className="mb-0">Bank Account Details</h6>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="mobile-main">
          <div className={` ${addBankStep1 || addBankStep2 || addBankStep3 ? "d-none" : ""}`}>

            {
              !addBankStep1 && !addBankStep2 && !addBankStep3 && <>
                  {showskeleton === false && userPrimaryBankData ? (
                  <div className="">
                    <div className="banklist">
                      <div className="banklist-header">
                        <div className="dflex align-items-center">
                          <div className="banklist-icon">
                            <img src={userPrimaryBankData?.bank?.bse_bank_img ? imgUrl + userPrimaryBankData?.bank?.bse_bank_img : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4bZWRAQW68fi5U7P6YTKYicX4smRbw0mRuw&usqp=CAU"}></img>


                          </div>
                          <div className="banklist-name ms-3">
                            <h6 className="mb-0 tx-uppercase"> {userPrimaryBankData?.bank?.bse_bank_name}


                            </h6>
                         
                          </div>
                        </div>
                      
                      </div>
                      <div className="banklist-content">
                        <ul>
                          <li><span>Account</span>{userPrimaryBankData?.ubd_acc_no}
                          </li>
                          <li><span>IFSC Code</span> {userPrimaryBankData?.ubd_ifsc}</li>
                          <li><span>Branch Name</span> {userPrimaryBankData?.ubd_bank_branch}</li>
                        </ul>
                        {userPrimaryBankData.ubd_api_mandateid !== null ? "" : <>
                          <hr></hr>
                          <div className="text-center">
                            <a href="#" className="btn btn-outline-primary btn-md" onClick={() => { navigate(`/bankmandate/${userPrimaryBankData?.ubd_id}`) }}>SETUP AUTOPAY</a>
                          </div></>}
                        {userPrimaryBankData.ubd_api_mandateid !== null ? <>
                          <hr></hr>
                          <h6>Active Autopay mandates</h6>
                          <hr></hr>
                          <ul>
                            <li><span style={{width:"132px"}}>Mandates Id:</span>{userPrimaryBankData.ubd_api_mandateid}</li>
                            <li><span style={{width:"132px"}}> Status:</span>{userPrimaryBankData.ubd_mandate_status == 0 ? <div className="text-danger">Incomplete <span><img src="/img/refresh.jpg" onClick={()=> {window.location.reload()}} style={{height:'15px', width:'15px', marginLeft:'5px'}}/></span></div> : ""}{userPrimaryBankData.ubd_mandate_status == 1 ? <div className="text-success">Approved</div> : ""}
                              {userPrimaryBankData.ubd_mandate_status == 2 ? <div className="text-warning">Reject</div> : ""}</li>
                              {userPrimaryBankData.ubd_max_amount ?<li ><span style={{width:"132px"}}>Mandate Amount:</span>{userPrimaryBankData.ubd_max_amount}</li>:""}
                            <li ><span style={{width:"132px"}}>Created on:</span>{userPrimaryBankData.ubd_mandate_startdate}</li>
                          </ul>
                        </> : ""}
                        {userPrimaryBankData.ubd_api_mandateid !== null && userPrimaryBankData.ubd_mandate_status == 0 ? <>
                            <hr></hr>
                            <div className="text-center">
                            <p>If the status above does not change, you can try again in 3 miniutes. </p>
                              <a href="#" className="btn btn-outline-primary btn-md" onClick={() => { onConfirmSA(userPrimaryBankData?.ubd_id)}}>Try Again</a>

                            </div></> : ''}
                        {userPrimaryBankData.ubd_mandate_status == 2 ?
                          <div className="banklist-content">
                            <div class="alert alert-warning mb-0 mt-2 tx-13" role="alert">Complete bank mandates to automate monthly SIP payments. <a href="#" onClick={() => { navigate(`/bankmandate/${userPrimaryBankData?.ubd_id}`) }}>Setup Autopay</a></div></div> : ""}

                      </div>
                    </div>
                  </div>)
              : (

                <div class="col-lg-4">
                  <div class="banklist">
                    <div class="banklist-header">
                      <div class="dflex align-items-center">
                        <div class="banklist-icon"><Skeleton width="40px" height="40px" /></div>
                        <div class="banklist-name ms-3">
                          <h6 class="mb-0 tx-uppercase"><Skeleton width="100px" height="10px" /></h6>
                          <p class="mb-0 tx-green"><Skeleton width="80px" height="10px" /></p>
                        </div>
                      </div>
                      <div class="morebutton ml-auto dropdown">
                        <button type="button" id="dropdown-basic" aria-expanded="false" class="dropdown-toggle btn btn-primary"><i class="icon-more2"></i></button>
                      </div>
                    </div>
                    <div class="banklist-content">
                      <ul>
                        <li><span>Account</span><Skeleton width="80px" height="10px" /></li>
                        <li><span>IFSC Code</span> <Skeleton width="80px" height="10px" /></li>
                        <li><span>Branch Name</span><Skeleton width="80px" height="10px" /></li>
                      </ul>
                      <hr />
                      <h6>Active Autopay mandates</h6>
                      <hr />
                      <ul>
                        <li><span>Mandates Id</span><Skeleton width="80px" height="10px" /></li>
                        <li>
                          <span>Status</span>
                          <div class="text-danger"><Skeleton width="80px" height="10px" /></div>
                        </li>
                        <li><span>Created on</span><Skeleton width="80px" height="10px" /></li>
                      </ul>
                      
                    </div>
                  </div>
                </div>

              )}

                {userBankData && userBankData?.map((value) => {

                  return (<>
                    <div className="">
                      <div className="banklist">
                        <div className="banklist-header">
                          <div className="dflex align-items-center">
                            <div className="banklist-icon">
                              {value?.bank?.bse_bank_img ? <img src={value?.bank?.bse_bank_img ? imgUrl + value?.bank?.bse_bank_img : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4bZWRAQW68fi5U7P6YTKYicX4smRbw0mRuw&usqp=CAU"}></img> : <Skeleton />}
                            </div>
                            <div className="banklist-name ms-3">
                              <h6 className="mb-0 tx-uppercase">{value?.bank?.bse_bank_name ? value?.bank?.bse_bank_name : <Skeleton width="300px" />}</h6>
                              {/* <p className="mb-0 tx-green">Primary Bank</p> */}
                            </div>
                          </div>
                          <Dropdown className="morebutton ml-auto">
                            <Dropdown.Toggle id="dropdown-basic" className="">
                              <i class="icon-more2"></i>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {value.ubd_mandate_status == 0 ?
                              <Dropdown.Item href="#/action-1" onClick={() => { setdeletebank(true); setubdid(value?.ubd_id) }}>Delete</Dropdown.Item>:''}
                              <Dropdown.Item href="#/action-2" onClick={() => { if (value.ubd_api_mandateid == null) { setMandateAlert(true) } else { setprimaryalert(true); } setprimubdid(value?.ubd_id) }}>Make Primary</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        <div className="banklist-content">
                          <ul>
                            {value?.ubd_acc_no ? <li><span>Account</span>{value.ubd_acc_no}</li> : <Skeleton width="" />}
                            {value?.ubd_ifsc ? <li><span>IFSC Code</span>{value?.ubd_ifsc}</li> : <Skeleton width="" />}
                            {value?.ubd_bank_branch ? <li><span>Branch Name</span>{value?.ubd_bank_branch}</li> : <Skeleton width="" />}
                          </ul>
                          {value.ubd_api_mandateid !== null || value.ubd_mandate_status == 2 ? "" : <>
                            <hr></hr>
                            <div className="text-center">
                              <a href="#" className="btn btn-outline-primary btn-md" onClick={() => { navigate(`/bankmandate/${value?.ubd_id}`) }}>SETUP AUTOPAY</a>
                            </div></>}
                          {value.ubd_api_mandateid !== null ? <>
                            <hr></hr>
                            <h6>Active Autopay mandates</h6>
                            <hr></hr>
                            <ul>
                              <li><span style={{width:"132px"}}>Mandates Id:</span>{value.ubd_api_mandateid}</li>
                              <li><span style={{width:"132px"}}>Status:</span>{value.ubd_mandate_status == 0 ? <div className="text-danger">Incomplete<span><img src="/img/refresh.jpg" onClick={()=> {window.location.reload()}} style={{height:'15px', width:'15px', marginLeft:'5px'}}/></span></div> : ""}{value.ubd_mandate_status == 1 ? <div className="text-success">Approved</div> : ""}{value.ubd_mandate_status == 2 ? <div className="text-warning">Reject</div> : ""}</li>
                              {value.ubd_max_amount ?<li><span style={{width:"132px"}}>Mandate Amount:</span>{value.ubd_max_amount}</li>:""}
                              <li><span style={{width:"132px"}}>Created on:</span>{value.ubd_mandate_startdate}</li>
                            </ul>
                          </> : ""}
                          {value.ubd_api_mandateid !== null && value.ubd_mandate_status == 0 ? <>
                            <hr></hr>
                            <div className="text-center">
                            <p>If the status above does not change, you can try again in 3 miniutes. </p>
                              <a href="#" className="btn btn-outline-primary btn-md" onClick={() => { onConfirmSA(value?.ubd_id)}}>Try Again</a>
                            </div></> : ''}
                          {mandateAlert == true && primubdid == value.ubd_id || value.ubd_mandate_status == 2 ?
                            <div className="banklist-content">
                              <div className="alert alert-warning mb-0 mt-2 tx-13" role="alert">Complete bank mandates to automate monthly SIP payments. <a href="#" onClick={() => { navigate(`/bankmandate/${value?.ubd_id}`) }}>Setup Autopay</a></div></div> : ""}
                        </div>
                      </div>

                    </div>
                  </>)


                })}
                {userBankData.length < 2 ? <>

                  <div className="">
                    <div className="addbanklist " onClick={AddBank}>
                      <img src="img/plus.png" className="wd-24 me-2"></img>
                      <p className="mb-0 tx-theme fw700">Add Another Bank</p>
                    </div>
                  </div>
                </> : ""}



              </>
            }
          </div>
          <div className="">
            {addBankStep1 === true && <>
              <div className="">
                <div className="bankdropdownbx">
                  <div className="bankdropdownbxClose" onClick={() => { Closeaddbank() }}>
                    <i class="icon-cross2"></i>
                  </div>
                  <h6>Choose Bank</h6>
                  <div className="from-group bankinput">
                    <label className="tx-gray">Bank is compulsory for openning an investment account</label>
                    <Select
                      // value={selectedOption}
                      name="bankName"
                      onChange={(e) => { setBankDetails1({ ...bankDetails1, bankName: e.value }) }}
                      placeholder="Type and search for bank"
                      options={bankData}
                    />
                  </div>

                  <button className="btn btn-primary mt-4 btn-lg btn-full" type="number" onClick={(e) => handlechangebank(e)}>
                    Next
                  </button>

                </div>
              </div>

            </>}
            {addBankStep2 === true && <>
              <div className="">
                <div className="bankdropdownbx ">
                  <div className="bankdropdownbxClose" onClick={() => { Closeaddbank() }}>
                    <i class="icon-cross2"></i>
                  </div>
                  <h6>Bank IFSC Code</h6>

                  <div className="from-group">
                    <label className=" tx-gray">Enter your IFSC number for</label>
                    <input className="w-100 mb-3" placeholder="Enter your IFSC code" type=""
                      name="neftIfscCode"
                      id="neftIfscCode"
                      // defaultValue={bankDetails1.neftIfscCode}
                      onChange={(e) => { handleInputChange1(e); handleNextClick(e) }}
                      onInput={(e) => e.target.value = e.target.value.toUpperCase()}
                      minLength="11"
                      maxLength="11"
                      pattern="^[A-Z]{4}0[A-Z0-9]{6}$"
                    >

                    </input>
                  </div>
                  {/* <div>
                    <button className="btn btn-primary btn-lg btn-full" type="number" onClick={handleNextClick}>
                      Next
                    </button>
                  </div> */}

                </div>
              </div>
            </>}
            {addBankStep3 === true && <>
              <div className="">
                <div className="bankdropdownbx ">
                  <div>
                    <div className="bankdropdownbxClose" onClick={() => { Closeaddbank() }}>
                      <i className="icon-cross2"></i>
                    </div>
                    <h6>Verify your Bank Account</h6>

                    <p className="tx-gray">Investments on Goalstox can be done using only saving bank accounts</p>
                  </div>
                  <div className="banklist" style={{ border: '1px solid var(--color-lighten05)' }}>
                    <div className="banklist-header justify-content-between align-items-start">
                      <div className="dflex align-items-center">
                        <div className="banklist-icon">
                          <img src={bankdetail?.bankimg ? imgUrl + bankdetail?.bankimg : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4bZWRAQW68fi5U7P6YTKYicX4smRbw0mRuw&usqp=CAU"}></img>
                        </div>
                        <div className="banklist-name ms-3">
                          <h6 className="mb-0 tx-uppercase">{bankdetail?.bankName}</h6>

                        </div>
                      </div>
                      {/* <p className="tx-green">Edit</p> */}
                    </div>
                    <div className="banklist-content">
                      <ul>
                        <li><span>Bank Name</span>{bankdetail?.bankName}</li>
                        <li><span>IFSC Code</span> {bankdetail?.Ifsccode}</li>
                        <li><span>Bank Branch</span> {bankdetail?.bankBranch}</li>
                      </ul>
                    </div>
                  </div>
                  <div className="mt-4 from-group">
                    <label className=" tx-gray">Enter Bank Account Number </label>
                    <input className="w-100 mb-3" placeholder="Eg : 134567876545" name="accNo" type="number" minLength="12"
                      maxLength="12" onChange={(e) => { handleInputChange1(e) }}></input>
                    <label className=" tx-gray">Select Bank Type </label>
                    <select
                      name="accType"
                      id="accType"
                      onChange={(e) => { handleInputChange1(e) }}
                      className="custom-select"
                    >
                      <option value="">Select bank type</option>
                      <option value="SB" >Savings Bank</option>
                      <option value="CB" >Current Bank</option>
                      <option value="NE" >NRE</option>
                      <option value="NO" >NRO</option>
                    </select>
                    {showOTPScreen == true ? <>
                      <label htmlFor="fname">OTP<span className="tx-red">*</span></label>
                      <input type="number" onChange={(e) => { setOTP(e.target.value) }} />
                      {counter === 0 ? (
                        <p className="tx-12 mt-2">
                          Didn't receive OTP? Resend{" "}
                          <span
                            className="tx-theme"
                            style={{ cursor: "pointer" }}
                            onClick={resendOtp}
                          >
                            (Click Here)
                          </span>
                        </p>
                      ) : (
                        <p className="tx-12 mt-2">
                          Resend OTP in {counter} seconds
                        </p>
                      )}</> : ""}
                  </div>

                  <div>
                    {showOTPScreen == true ?
                      <button type="button" className="btn btn-primary btn-lg btn-full mt-4" disabled={isLoading} onClick={verifyOTP}>  {isLoading ? (
                        <img src="/img/loder01.gif" width="60px" height="11px" />
                      ) : (
                        "Verify OTP"
                      )}</button>
                      : <button type="button" className="btn btn-primary btn-lg btn-full mt-4" onClick={()=>{sendOTP('new')}} disabled={isLoading}>  {isLoading ? (
                        <img src="/img/loder01.gif" width="60px" height="11px" />
                      ) : (
                        "Send OTP"
                      )}</button>

                    }
                  </div>

                </div>
              </div>
            </>}
          </div>
        </div>

      </MobileView>



      <SweetAlert warning confirmBtnCssClass='alertpop' title={` Are you sure ? you want to delete bank account `} confirmBtnText='Confirm'
        cancelBtnText="Cancel"
        show={deletebank} onConfirm={Confirm} onCancel={Cancel} btnSize="md" showCancel
        cancelBtnBsStyle="danger"
      >
      </SweetAlert>
      <SweetAlert warning confirmBtnCssClass='alertpop' title={` Are you sure ? you want to change the primary bank `} confirmBtnText='Confirm'
        cancelBtnText="Cancel"
        show={primaryalert} onConfirm={Confirmprim} onCancel={Cancelprim} btnSize="md" showCancel
        cancelBtnBsStyle="danger"
      >
      </SweetAlert>

      <Modal className="verifybse" show={showmodal} onHide={handlemodalclose}>
        <Modal.Body>
          <div className="verifybse-content">
            <button className="pop-close" onClick={() => { handlemodalclose() }}><i class="d-icon-times"></i></button>
            <h5>Verify OTP</h5>
            <p>OTP has been sent your register mobile number</p>
            <div className="from-group mt-2">
              <label>Verify OTP</label>
              <input
                type="number"
                name="mobile_otp"
                onChange={(e) => { setOtpData(e.target.value) }}
              ></input>
              <p className="mt-2 mb-0" style={{ fontSize: "12px" }}>We will send you OTP on your registered Mobile no.</p>
              {counter === 0 ? (
                <p className="tx-12 ">
                  Didn't receive OTP? Resend{" "}
                  <span
                    className="tx-theme"
                    style={{ cursor: "pointer" }}
                    onClick={resendOtp}
                  >
                    (Click Here)
                  </span>
                </p>
              ) : (
                <p className="tx-12 ">
                  Resend OTP in {counter} seconds
                </p>
              )}
            


              <button
                                                className="btn btn-primary btn-lg btn-full mb-1"
                                                onClick={verifyotpdelete}
                                                disabled={isLoading}
                                            >
                                                {isLoading ? (
                                                    <img src="/img/loder01.gif" width="60px" height="11px" />
                                                ) : (
                                                    "Verify"
                                                )}
                                            </button>
            </div>
          </div>

        </Modal.Body>

      </Modal>
    </>
  )
}
export default Bank