import React, { useEffect, useRef, useState } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { BrowserView, MobileView } from "react-device-detect";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { ApiService } from "../../Components/Services/apiServices";
let apiServices = new ApiService();
function StartInvestment() {
    const navigate = useNavigate()
    const didMountRef = useRef(true)
    const { id } = useParams()
    const [message, setMessage] = useState("")
    const [debtdata, setdebtdata] = useState([])
    const [equityCode, setequityCode] = useState([])
    const [equityCodeName, setequityCodeName] = useState([])
    const [debtCode, setdebtCode] = useState([])
    const [debtCodeName, setdebtCodeName] = useState([])
    const [debtamount, setdebtamount] = useState("")
    const [equityamount, setequityamount] = useState("")
    const [imageUrl, setimageUrl] = useState("")
    const [equitydata, setequitydata] = useState([])
    const [show, setShow] = useState(false);
    const [showcontinue, setshowcontinue]=useState("")
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        if (didMountRef.current) {


            apiServices.bsegetuserdataPostRequest({})
                .then(result => {

                });
            const dataString = {

                gs_id: id
            }
            apiServices.getmutualInvestmentDatarequest(dataString).then(result => {

                if (result.data.status == 'success') {
                    setMessage(result.data.message)
                    setdebtamount(result?.data?.debtamt)
                    setequityamount(result?.data?.equityamt)
                    setdebtdata(result?.data?.debt)
                    setequitydata(result?.data?.equity)
                    setimageUrl(result?.data?.imgeURL)
                }
                else if (result.data.status == 'error') {
                    toast.info(result?.data?.message)
                    setTimeout(() => {
                        navigate("/risk-assessment")
                    }, 4000)

                }
            })

            const mergedequityanddebt = JSON.parse(sessionStorage.getItem('mergedequityanddebt'));
            const debtArrayName = JSON.parse(sessionStorage.getItem('debtArrayName'));
            const equityArrayName = JSON.parse(sessionStorage.getItem('equityArrayName'));
            const equityArray = JSON.parse(sessionStorage.getItem('equityarray'));
            const debtArray = JSON.parse(sessionStorage.getItem('debtarray'));
            
            if(mergedequityanddebt?.length>0){
                setshowcontinue(mergedequityanddebt)
            }
            else{
                setshowcontinue("") 
            }
            if (debtArrayName?.length > 0) {
                setdebtCodeName(debtArrayName)
                setdebtCode(debtArray)
            }
            if (equityArrayName?.length > 0) {
                setequityCodeName(equityArrayName)
                setequityCode(equityArray)
            }



        }
        didMountRef.current = false;
    }, [equityCodeName, debtCodeName]);



    const clickonequityfilter = (equity_code, equity_name) => {

        console.log(equity_code,"equity_code", equity_name,equity_name)
        var equityArray = equityCode
        var equityArrayName = equityCodeName
        const equityCodeExists = equityArray.includes(equity_code);
        const equitynameexist = equityArrayName.includes(equity_name)
        if (equityCodeExists || equitynameexist) {

            equityArray = equityArray.filter(item => item !== equity_code);
            equityArrayName = equityArrayName.filter(item => item !== equity_name);
        } else {

            equityArray = [...equityArray, equity_code];
            equityArrayName = [...equityArrayName, equity_name];
        }
        setequityCode(equityArray)
        setequityCodeName(equityArrayName)
       
        sessionStorage.setItem('equityarray', JSON.stringify(equityArray));
        const mergedArraycode = [...equityArray, ...debtCode];

        sessionStorage.setItem('equityArrayName', JSON.stringify(equityArrayName));
        // Store the merged array in session storage
        sessionStorage.setItem('mergedequityanddebt', JSON.stringify(mergedArraycode));
        if(mergedArraycode?.length>0){
            setshowcontinue(true)
        }
        else{
            setshowcontinue(false) 
        }
        handleShow()
    }


    const clickondebtfilter = (debt_code, debt_name) => {

        console.log(debt_code,"equity_code", debt_name,)
        var debtArray = debtCode
        var debtArrayName = debtCodeName
        console.log(debtArrayName, "debtArrayNamedebtArrayName")
        const debtCodeExists = debtArray.includes(debt_code);
        const debtnameexist = debtArrayName.includes(debt_name)
        if (debtCodeExists || debtnameexist) {

            debtArray = debtArray.filter(item => item !== debt_code);
            debtArrayName = debtArrayName.filter(item => item !== debt_name);
        } else {

            debtArray = [...debtArray, debt_code];
            debtArrayName = [...debtArrayName, debt_name];
        }
        setdebtCode(debtArray)
        setdebtCodeName(debtArrayName)
       
        const mergedArrayCode = [...equityCode, ...debtArray];
        sessionStorage.setItem('debtarray', JSON.stringify(debtArray));
        sessionStorage.setItem('debtArrayName', JSON.stringify(debtArrayName));
        sessionStorage.setItem('mergedequityanddebt', JSON.stringify(mergedArrayCode));
        if(mergedArrayCode?.length>0){
            setshowcontinue(true)
        }
        else{
            setshowcontinue(false) 
        }
        handleShow()

    }

    return (
        <>

            <BrowserView>
                <ToastContainer position={toast.POSITION.BOTTOM_CENTER} />
                <Header />
                <div className="subheader subheader-small">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                              
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/dashboard">Dashboard</Breadcrumb.Item>
                                    <Breadcrumb.Item active href="">Start Investment</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="sec-pad-sm">
                    <div className="container">
                        <div className="row g-2">
                            {message?
                            <div className="col-lg-12" >
                                <div className="panel color-primary-style mb-2">
                                    <div className="panel-body">
                                       
                                        <h6 className="mb-0">{message}</h6>
                                      
                                        
                                    </div>
                                </div>
                            </div>
                            :<div className="col-lg-12" >
                                 <Skeleton width="100%" height="50px" />
                                </div>}
                            <div className="col-lg-6">
                            <div className="panel">
                                <div className="panel-header"><h6 className="mb-0 fw700">Equity (₹{equityamount})</h6></div>
                                <div className="panel-body infund-list">
                                    <ul>
                               
                                        {equitydata.length > 0 ?
                                        equitydata?.map((value, index) => (
                                        <li className={equityCodeName.includes(value.mf_scheme_name)?"active":""} onClick={(e) => { clickonequityfilter(value.mf_id, value.mf_scheme_name); }}>
                                            <div className="infundbox">
                                                <span className="infundbox-check"></span>
                                                <div className="infundbox-details">
                                                    <h6 className="mb-0">{value.mf_scheme_name}</h6>
                                                    <div className="inmetabox">
                                                        <span className="inmetabox-name">{value.mf_scheme_code}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>))
                                        :
                                        <>
                                        <li className="">
                                            <div className="infundbox">
                                                <span className="infundbox-check"></span>
                                                <div className="infundbox-details">
                                                <Skeleton width="100%" height="10px" />
                                                <Skeleton width="100%" height="10px" />
                                                   
                                                </div>
                                            </div>
                                        </li>
                                        <li className="">
                                            <div className="infundbox">
                                                <span className="infundbox-check"></span>
                                                <div className="infundbox-details">
                                                <Skeleton width="100%" height="10px" />
                                                <Skeleton width="100%" height="10px" />
                                                   
                                                </div>
                                            </div>
                                        </li>
                                        <li className="">
                                            <div className="infundbox">
                                                <span className="infundbox-check"></span>
                                                <div className="infundbox-details">
                                                <Skeleton width="100%" height="10px" />
                                                <Skeleton width="100%" height="10px" />
                                                   
                                                </div>
                                            </div>
                                        </li>
                                        <li className="">
                                            <div className="infundbox">
                                                <span className="infundbox-check"></span>
                                                <div className="infundbox-details">
                                                <Skeleton width="100%" height="10px" />
                                                <Skeleton width="100%" height="10px" />
                                                   
                                                </div>
                                            </div>
                                        </li>
                                        </>}
                                      
                                    </ul>
                                </div>
                            </div>
                            </div>
                            <div className="col-lg-6">
                            <div className="panel">
                                <div className="panel-header"><h6 className="mb-0 fw700">Debt(₹ {debtamount})</h6></div>
                                <div className="panel-body infund-list">
                                    <ul>
                               
                                    {debtdata.length > 0 ?
                                    debtdata?.map((value, index) => (
                                        <li className={debtCodeName.includes(value.mf_scheme_name) ?"active":""} onClick={(e) => { clickondebtfilter(value.mf_id, value.mf_scheme_name); }}>
                                            <div className="infundbox">
                                                <span className="infundbox-check"></span>
                                                <div className="infundbox-details">
                                                    <h6 className="mb-0">{value.mf_scheme_name}</h6>
                                                    <div className="inmetabox">
                                                        <span className="inmetabox-name">{value.mf_scheme_code}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>))
                                          :
                                          <>
                                          <li className="">
                                              <div className="infundbox">
                                                  <span className="infundbox-check"></span>
                                                  <div className="infundbox-details">
                                                  <Skeleton width="100%" height="10px" />
                                                  <Skeleton width="100%" height="10px" />
                                                     
                                                  </div>
                                              </div>
                                          </li>
                                          <li className="">
                                              <div className="infundbox">
                                                  <span className="infundbox-check"></span>
                                                  <div className="infundbox-details">
                                                  <Skeleton width="100%" height="10px" />
                                                  <Skeleton width="100%" height="10px" />
                                                     
                                                  </div>
                                              </div>
                                          </li>
                                          <li className="">
                                              <div className="infundbox">
                                                  <span className="infundbox-check"></span>
                                                  <div className="infundbox-details">
                                                  <Skeleton width="100%" height="10px" />
                                                  <Skeleton width="100%" height="10px" />
                                                     
                                                  </div>
                                              </div>
                                          </li>
                                          <li className="">
                                              <div className="infundbox">
                                                  <span className="infundbox-check"></span>
                                                  <div className="infundbox-details">
                                                  <Skeleton width="100%" height="10px" />
                                                  <Skeleton width="100%" height="10px" />
                                                     
                                                  </div>
                                              </div>
                                          </li>
                                          </>}
                                        

                                       
                                    </ul>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
                
     {showcontinue ?
          <div className="popup-addfundcart">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <h6 className="mg-b-5">Total Fund Selected:  {Number(debtCodeName?.length) + Number(equityCodeName?.length)}</h6>
                  <div className="pop-addfundlist">
                    Added To Your Bucket Now You Can Click On Continue
                  </div>
                </div>
                <div className="col-lg-4 tx-right"><div class="theme-button">
                  <a href class="btn btn-primary btn-md me-3"  onClick={() => { navigate("/multiplefunds-SIP") }}>Continue</a>

                </div></div>
              </div>
            </div>
          </div> :""}
      
            </BrowserView>
            <MobileView>
                <header className="mheader">
                    <div className="container">
                        <div className="mheader-left">
                            <div className="dflex align-items-center" >
                                <i class="d-icon-arrow-left tx-22" onClick={() => { navigate("/dashboard") }}></i>
                                {/* <div className="ms-3">
            <h6 className="mb-0"> Start Investments</h6>
            </div> */}
                            </div>
                        </div>
                    </div>
                </header>

                <section className="sec-pad-sm mt-5">
                    <div className="container">
                        <div className="row g-2">
                            <div className="col-lg-12" >
                                <div className='p-3 panel holding-panel phbox active mg-b-15'>
                                    <h6>You should invest 0% in debt and 100% of Equity of total amount of ₹ {equityamount}</h6>
                                </div>
                            </div>
                            <div className="col-lg-6 mt-3">
                                <div className="p-4 panel holding-panel phbox active">
                                    <div className="p-2 ">
                                        <h6>Equity(₹ {equityamount})</h6>
                                    </div>
                                    {equitydata.length > 0 ? <>
                                        {equitydata?.map((value, index) => {
                                            return <>

                                                <div className='p-3 panel holding-panel phbox active mg-b-15 mb-2'>

                                                    <div className="sflist">
                                                        <ul>
                                                            <li ><label class="container-checkbox">{value.mf_scheme_name}<input type="checkbox" onChange={(e) => { clickonequityfilter(value.mf_id, value.mf_scheme_name); }}
                                                                value={value.mf_scheme_name}
                                                                checked={equityCodeName.includes(value.mf_scheme_name) ? true : false}
                                                            /><img src={value?.mf_image ? imageUrl + value?.mf_image : "/img/defaltimage.png"} style={{ width: "55px", height: "55px" }}></img><span class="checkmark"></span></label></li>
                                                        </ul>
                                                    </div>




                                                </div>

                                            </>
                                        })}


                                    </> : ""}

                                </div>


                            </div>
                            <div className="col-lg-6 mt-3">
                                <div className="p-4 panel holding-panel phbox active">


                                    <div className="p-2">
                                        <h6>Debt(₹ {debtamount})</h6>
                                    </div>
                                    {debtdata.length > 0 ? <>
                                        {debtdata?.map((value, index) => {
                                            return <>

                                                <div className='p-3 panel holding-panel phbox active mg-b-15 mb-2'>

                                                    <div className="sflist">
                                                        <ul>
                                                            <li ><label class="container-checkbox">{value.mf_scheme_name}<input type="checkbox" onChange={(e) => { clickondebtfilter(value.mf_id, value.mf_scheme_name); }}
                                                                value={value.mf_scheme_name}
                                                                checked={debtCodeName.includes(value.mf_scheme_name) ? true : false}
                                                            /><img src={value?.mf_image ? imageUrl + value?.mf_image : "/img/defaltimage.png"} style={{ width: "55px", height: "55px" }}></img><span class="checkmark"></span></label></li>
                                                        </ul>
                                                    </div>




                                                </div>

                                            </>
                                        })}


                                    </> : ""}


                                </div>


                            </div>


                        </div>
                    </div>
                </section>
            </MobileView>

            {/* <Offcanvas show={show} onHide={handleClose} placement={"bottom"} size="xl">
                <Offcanvas.Header >

                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="p-3">
                        <button className="pop-close me-5" onClick={() => { handleClose() }}><i class="d-icon-times"></i></button>
                        <div className="d-flex justify-content-around ">
                            <div>
                                <p className="">Total Fund Selected : {Number(debtCodeName?.length) + Number(equityCodeName?.length)}</p>
                                <p>Added to your Bucket now you can click on continue</p>
                            </div>
                            {showcontinue==true?<> <div>   <button className="btn btn-primary btn-lg btn-full mt-1" onClick={() => { navigate("/multiplefunds-SIP") }}>Continue</button></div></>:""}

                           
                        </div>
                    </div>


                </Offcanvas.Body>
            </Offcanvas> */}
        </>
    )
}

export default StartInvestment